import { TableCell, TableRow } from "@mui/material";
import React from "react";
import selectlineAPI from "../services/SelectlineApi";
import { ExportItem } from "./export-item";

export class ExportPartialSum extends ExportItem {
    private name: string;
    constructor(name: string) {
        super();
        this.name = name;
    }

    showItem(index: number): JSX.Element {
        return (
            <TableRow key={index}>
                <TableCell style={{ paddingTop: "8px", paddingBottom: "8px", fontSize: "24px" }}>&sum;</TableCell>
                {Array.from({ length: 2 }, (_, index) => (
                    <TableCell key={index}></TableCell>
                ))}
                <TableCell align="left" style={{ fontWeight: 900, fontSize: "0.8rem" }}>
                    Teilsumme {this.name}
                </TableCell>
            </TableRow>
        );
    }

    saveItem = (documentKey: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            selectlineAPI
                .savePartialSum(documentKey)
                .then(() => {
                    resolve();
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    };
}
