import { ButtonForDropdown } from "@abs-safety/lock-book-web-ui";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

const S = {
    Paragraph: styled.p`
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 20px;
        font-size: 0.9rem;
    `,
    ExpanderText: styled.p`
        text-align: left;
        font-size: 1rem;
        font-weight: 600;
    `,
};

function ExpandComponent(props: { label: string; map: Map<string, string> }) {
    const [expanded, setExpanded] = useState(false);

    const toggleAusgeklappt = useCallback(() => {
        setExpanded((prevState) => !prevState);
    }, []);

    return (
        <div>
            <ButtonForDropdown
                color="black"
                onToggle={function noRefCheck() {
                    toggleAusgeklappt();
                }}
                dropdownIsOpen={expanded}
                removeButtonBorder
            >
                <S.ExpanderText>{props.label}</S.ExpanderText>
            </ButtonForDropdown>
            {expanded && (
                <div>
                    {[...props.map.entries()].map(([key, value]) => (
                        <S.Paragraph key={key}>
                            <b>{key}</b>: {value}
                        </S.Paragraph>
                    ))}
                </div>
            )}
        </div>
    );
}

export default ExpandComponent;
