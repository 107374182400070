import { observer } from "mobx-react";
import React from "react";
import AttributeComponent from "../../common/components/AttributeComponent";
import ExpandComponent from "../../common/components/ExpandComponent";
import exportStore from "../../common/stores/ExportStore";
import { LadderProject } from "../models/LadderProject";

function LadderInfoSection() {
    if (exportStore.getProject() === undefined) {
        return <div></div>;
    }

    return (
        <div>
            <AttributeComponent
                readonly={true}
                label={"Bauvorhaben"}
                value={(exportStore.getProject() as LadderProject).getConstructionProject()}
            />
            <div>
                {[...(exportStore.getProject() as LadderProject).getAdditionalInfos().entries()].map(([key, value]) => (
                    <ExpandComponent key={key} label={key} map={value} />
                ))}
            </div>
        </div>
    );
}
export default observer(LadderInfoSection);
