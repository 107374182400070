import { Input } from "@abs-safety/lock-book-web-ui";
import useId from "@mui/utils/useId";
import React from "react";

function AttributeComponent(props: { label: string; value: string; onChange?: any; readonly?: boolean }) {
    const id = useId();
    return (
        <div style={{ marginTop: 20 }}>
            <Input
                id={id}
                type={"text"}
                onChange={(event) => {
                    const { value } = event.target;
                    props.onChange(value);
                }}
                required
                className="outlined-required"
                label={props.label}
                value={props.value}
                readOnly={props.readonly ? props.readonly : false}
                style={{
                    width: "100%",
                    padding: "12px, 10px !important",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            />
        </div>
    );
}

export default AttributeComponent;
