import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import React, { ReactNode } from "react";
import ExportButton from "../../components/ExportButton";
import TextFieldModal from "../../components/modals/TextFieldModal";
import commentDialogStore from "../../stores/CommentDialogStore";
import exportStore from "../../stores/ExportStore";

type ExportSectionProps = {
    children?: ReactNode;
};

const ExportSection: React.FC<ExportSectionProps> = (props) => {
    return (
        <div className="resultComponent, componentContainer, resultTable">
            {commentDialogStore.getShowDialog() && (
                <TextFieldModal
                    comment={commentDialogStore.getComment()}
                    onClickFunc={() => {
                        commentDialogStore.toggleShowDialog();
                    }}
                />
            )}
            <div className="subComponentContainer">
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item sm={4}>
                        {props.children}
                    </Grid>
                    <Grid
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        item
                        sm={4}
                    ></Grid>
                    <Grid item sm={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ExportButton onClick={() => exportStore.export()} />
                    </Grid>
                </Grid>
            </div>

            <TableContainer className="testTableContainer" component={Paper} style={{ marginTop: 50 }}>
                <Table sx={{ minWidth: "90%", maxWidth: "100%" }} stickyHeader aria-label="sticky table">
                    <TableHead style={{ zIndex: -1 }}>
                        <TableRow key={"headerRow"}>
                            <TableCell
                                style={{
                                    backgroundColor: "#f9f7f3",
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                }}
                            >
                                Produkt
                            </TableCell>
                            {Array.from({ length: 2 }, (_, index) => (
                                <TableCell
                                    style={{
                                        backgroundColor: "#f9f7f3",
                                    }}
                                    key={index}
                                ></TableCell>
                            ))}
                            <TableCell
                                style={{
                                    backgroundColor: "#f9f7f3",
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                }}
                                align="right"
                            >
                                Anzahl
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{exportStore.showItems()}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default observer(ExportSection);
