import { ProductType } from "../../common/models/enums";
import errorStore from "../../common/stores/ErrorStore";
import { RoofSectionType } from "../models/enums";
import { GuardSystem } from "../models/GuardSystem";
import {
    Additions,
    AdditionsV2,
    AnchoragePoint,
    AttachementPointComponent,
    Corner,
    Edge,
    EdgeAttachment,
    FallProtectionDataSet,
    Gate,
    GeneratedAddition,
    GeneratedAddition2,
    GuardRail,
    JSONRoofAcces,
    Position,
    Post,
    RailingDataSet,
    RoofDataSet,
    Surface,
    UserAddition,
    WorldDataBondingParent,
    WorldDataDrainageParent,
    WorldDataInsulationParent,
    WorldDataMoveableRailingParent,
    WorldDataPrimerParent,
    WorldDataProduct,
    WorldDataProductWithPosition,
    WorldDataProductWithSurfaceRef,
    WorldDataPvParent,
    WorldDataSealingParent,
    WorldDataSet,
    WorldDataSystem,
    WorldDataVapourBarrierParent,
} from "../models/interfaces";
import { JSONProject } from "../models/lockBookDrawInterface";
import { OrderProject } from "../models/OrderProject";
import { Plan } from "../models/Plan";
import { Product } from "../models/Product";
import { RailSystem } from "../models/RailSystem";
import { RoofAccess } from "../models/RoofAccess";
import { RoofSection } from "../models/RoofSection";
import { System } from "../models/System";
import { createDrawListGenerator } from "./DrawListGenerator";
import { isPointOnRoofSection } from "./WindingNumber";

export class JSONHandler {
    lockBookDrawProjectResponse: JSONProject;
    fallprotection: FallProtectionDataSet | undefined = undefined;
    rails: RailingDataSet | undefined = undefined;
    concreteSurfaces: Surface[] | undefined = [];
    roofDataSet: RoofDataSet | undefined = undefined;
    roofAccesses: JSONRoofAcces[] | undefined = undefined;
    indexRoofSections = 1;
    drainageProducts: WorldDataProductWithSurfaceRef[] | undefined = undefined;
    vapourBarrierProducts: WorldDataProductWithSurfaceRef[] | undefined = undefined;
    sealingProducts: WorldDataProductWithSurfaceRef[] | undefined = undefined;
    primerProducts: WorldDataProductWithSurfaceRef[] | undefined = undefined;
    bondingProducts: WorldDataProductWithSurfaceRef[] | undefined = undefined;
    insulationProducts: WorldDataProductWithPosition[] | undefined = undefined;
    pvProducts: WorldDataProductWithPosition[] | undefined = undefined;
    moveableRailingSystems: WorldDataSystem[] | undefined = undefined;

    constructor(lockBookDrawProjectResponse: JSONProject) {
        this.lockBookDrawProjectResponse = lockBookDrawProjectResponse;
    }

    setProject() {
        return this.setPlans();
    }

    emptyDataStructure() {
        this.bondingProducts = undefined;
        this.primerProducts = undefined;
        this.sealingProducts = undefined;
        this.vapourBarrierProducts = undefined;
        this.drainageProducts = undefined;
        this.insulationProducts = undefined;
        this.pvProducts = undefined;
        this.moveableRailingSystems = undefined;
        this.fallprotection = undefined;
        this.rails = undefined;
        this.concreteSurfaces = undefined;
        this.roofDataSet = undefined;
        this.roofAccesses = undefined;
    }

    private static createProductFromWorldDataProduct(
        product: WorldDataProduct,
        parent: Plan | RoofSection,
        type: ProductType,
    ) {
        const plan: Plan = parent instanceof Plan ? parent : parent.getPlan();
        const newProduct: Product = new Product(
            product.components.PRODUCT.value,
            product.components.PRODUCT.value,
            product?.components?.AMOUNT?.value?.dimension[0],
            plan,
        );

        newProduct.setType(type);
        newProduct.setName(product.components.PRODUCT.value);
        newProduct.setAmount(product?.components?.AMOUNT?.value?.dimension[0]);
        newProduct.setUnit(product.components.AMOUNT.value.unit);

        newProduct.setPartOf(parent instanceof Plan ? parent.getTitle() : parent.getPlan().getTitle());

        newProduct.setPlan(plan);
        newProduct.setRoofSection(parent instanceof RoofSection ? parent : null);

        return newProduct;
    }

    private static createProduct(product: any, parent: Plan | RoofSection | GuardSystem | RailSystem): Product {
        const plan: Plan =
            parent instanceof Plan
                ? parent
                : parent instanceof RoofSection
                  ? parent.getPlan()
                  : parent.getRoofSection().getPlan();

        const newProduct: Product = new Product(product.id, product.id, product?.amount?.dimension[0], plan);
        newProduct.setType(parent instanceof RailSystem ? ProductType.RAILING : product.type);
        newProduct.setUnit(product.amount.unit);
        newProduct.setPartOf(
            parent instanceof Plan
                ? parent.getTitle()
                : parent instanceof RoofSection
                  ? parent.getPlan().getTitle()
                  : parent.getRoofSection().getPlan().getTitle() + " " + parent.getName(),
        );
        newProduct.setPlan(plan);
        newProduct.setRoofSection(
            parent instanceof RoofSection
                ? parent
                : parent instanceof GuardSystem || parent instanceof RailSystem
                  ? parent.getRoofSection()
                  : null,
        );
        return newProduct;
    }

    private static getProductType(entity: Plan | RoofSection | GuardSystem | RailSystem, anchorPoint: AnchoragePoint) {
        if (entity instanceof Plan) return ProductType.STANDALONE;

        if (entity instanceof GuardSystem && entity.getType() === RoofSectionType.TEMPORARY_CABLE)
            return ProductType.STANDALONE;

        if (anchorPoint.product.getId().startsWith("SY")) return ProductType.ADDITION;

        return anchorPoint.isEnforced ? ProductType.EDGE : ProductType.INTERMEDIATE;
    }

    reduceRoofSection(newPlan: Plan): RoofSection[] | undefined {
        return this.concreteSurfaces?.reduce((roofSections: RoofSection[], surface: Surface) => {
            const name = surface.name ? surface.name : "Dachfläche: " + this.indexRoofSections;
            const newRoofSection = new RoofSection(name, newPlan, surface.material, surface.base.z, surface.id);
            this.indexRoofSections++;

            const lines = [];
            for (const { lines: drawLines } of createDrawListGenerator(surface.draw)) {
                lines.push(...drawLines);
            }

            lines.push(
                ...surface.draw.reduce((edgePoints, edgePoint) => {
                    if (edgePoint?.edgeAttachments) {
                        this.addEdgeAttachment(edgePoint.edgeAttachments, newRoofSection);
                    }
                    return edgePoints;
                }, []),
            );

            newRoofSection.setRoofedges(lines);
            roofSections.push(newRoofSection);
            return roofSections;
        }, []);
    }

    addNewProductToEntities(
        newCreatedProduct: Product,
        entity: Plan | RoofSection | GuardSystem | RailSystem | RoofAccess,
    ) {
        entity instanceof Plan || entity instanceof RoofSection
            ? entity.addProductToGroup(newCreatedProduct)
            : entity instanceof GuardSystem
              ? entity.getRoofSection().addProductToGroup(newCreatedProduct)
              : (entity.getRoofSection().addProductToGroup(newCreatedProduct),
                entity.addProductToGroup(newCreatedProduct));
    }

    createRailSystem(roofSection: RoofSection, guardRail: GuardRail) {
        const newRailSystem = new RailSystem(guardRail.name, roofSection);
        newRailSystem.setName(guardRail.name);
        roofSection.setRailSystems(roofSection.getRailSystems().concat(newRailSystem));
        if (guardRail.type.startsWith("SG2-GUARD-")) {
            const newProduct: Product = new Product(
                guardRail.id,
                guardRail.type,
                this.calculate(guardRail),
                roofSection.getPlan(),
            );

            newProduct.setUnit("M");
            newProduct.setType(ProductType.RAILING);
            this.addNewProductToEntities(newProduct, newRailSystem);
        }

        this.addAdditionsToObject(guardRail.additions_v2, newRailSystem);
        guardRail?.corners?.reduce((corners: Corner[], corner: Corner) => {
            this.addAdditionsToObject(corner.additions_v2, newRailSystem);
            return corners;
        }, []);
        guardRail?.edges?.reduce((edges: Edge[], edge: Edge) => {
            edge?.gates?.reduce((gates: Gate[], gate: Gate) => {
                this.addAdditionsToObject(gate.additions_v2, newRailSystem);
                return gates;
            }, []);
            edge?.posts?.reduce((posts: Post[], post: Post) => {
                this.addAdditionsToObject(post.additions_v2, newRailSystem);
                return posts;
            }, []);
            return edges;
        }, []);
    }

    private setAnchoragepointOnRoof(index: number, roofSection: RoofSection, anchorPoint: AnchoragePoint): void {
        /*
        Put the product in PLAN so it can always grouped by the biggest entity
        */
        const guardSystemId: string = this.getGuardSystemByAnchorpointIndex(index);
        const roofsectionsystemen: GuardSystem[] = [];
        roofSection
            .getPlan()
            .getRoofSections()
            .forEach((roofSection: RoofSection) => {
                const system = roofSection.getGuardSystems().find((gs) => gs.getId() == guardSystemId);
                if (system) {
                    roofsectionsystemen.push(system);
                }
            });
        const guardSystemIsDefinedOnRoofSection = roofsectionsystemen.find((gs) => gs.getId() == guardSystemId);
        if (guardSystemId && !guardSystemIsDefinedOnRoofSection) {
            this.addNewGuardSystemToRoofSection(guardSystemId, roofSection, anchorPoint);
        } else if (guardSystemId && guardSystemIsDefinedOnRoofSection) {
            this.addAnchoragePointToGuardSystem(guardSystemIsDefinedOnRoofSection, anchorPoint);
        } else {
            this.addProductToEntity(anchorPoint, roofSection, ProductType.STANDALONE);
        }
    }

    private setItemOnEntity(entity: Plan | RoofSection, item: WorldDataProduct, type: ProductType) {
        const product = JSONHandler.createProductFromWorldDataProduct(item, entity, type);
        JSONHandler.addProductToEntities(product, entity);
    }

    getDistance(corner1: Corner, corner2: Corner) {
        const y = corner2.position.x - corner1.position.x;
        const x = corner2.position.y - corner1.position.y;
        const z = corner2.position.z - corner1.position.z;

        return Math.sqrt(x * x + y * y + z * z);
    }

    calculate(guardRail: GuardRail): number {
        const guardRailLength = guardRail.edges.reduce((currentLength, _, edgeIndex) => {
            const { position: startPos } = guardRail.corners[edgeIndex];
            const { position: endPos } = guardRail.corners[(edgeIndex + 1) % guardRail.corners.length];
            return currentLength + this.vectorLength(this.pointDiff(endPos, startPos));
        }, 0);
        return Math.ceil(guardRailLength);
    }

    squaredVectorLength = (v: { x: number; y: number; z: number }): number => v.x * v.x + v.y * v.y + v.z * v.z;

    vectorLength = (v: { x: number; y: number; z: number }): number => Math.sqrt(this.squaredVectorLength(v));

    pointDiff = (p1: Position, p2: Position) => ({
        x: p1.x - p2.x,
        y: p1.y - p2.y,
        z: p1.z - p2.z,
    });

    setRailOnRoofSection(roofSection: RoofSection, guardRail: GuardRail) {
        const railSystem: RailSystem | undefined = roofSection.getRailSystems().find((railSystem: RailSystem) => {
            return railSystem.getId() == guardRail.name;
        });
        if (!railSystem) {
            this.createRailSystem(roofSection, guardRail);
        }
    }

    addEdgeAttachment(edgeAttachments: EdgeAttachment[], newRoofSection: RoofSection) {
        edgeAttachments.forEach((edgeAttachment: EdgeAttachment) => {
            const roofAccess = this.roofAccesses?.find((dataset: JSONRoofAcces) => {
                return dataset.data.attachmentId == edgeAttachment.id;
            });
            if (roofAccess) {
                const newRoofAccess = new RoofAccess("Dachzugang", newRoofSection);
                newRoofSection.setRoofAccesses(newRoofSection.getRoofAccesses().concat(newRoofAccess));
                if (roofAccess?.data?.product) {
                    const newProduct = JSONHandler.createProduct(roofAccess?.data?.product, newRoofSection);
                    newProduct.setType(ProductType.ROOFACCESS);
                    newProduct.setPartOf(newRoofSection.getPlan().getTitle() + " " + newRoofSection.getId());
                    newProduct.setRoofSection(newRoofSection);
                    this.addNewProductToEntities(newProduct, newRoofAccess);
                }

                roofAccess.data?.additions?.generatedAdditions
                    ?.filter((generatedAddition: GeneratedAddition) => {
                        return !roofAccess?.data?.additions?.deletedGeneratedAdditions?.includes(generatedAddition.id);
                    })
                    .forEach((generatedAddition: GeneratedAddition) => {
                        const newProduct = JSONHandler.createProduct(generatedAddition.product, newRoofSection);
                        newProduct.setType(ProductType.ROOFACCESS);
                        newProduct.setPartOf(newRoofSection.getPlan().getTitle() + " " + newRoofSection.getId());
                        newProduct.setRoofSection(newRoofSection);
                        this.addNewProductToEntities(newProduct, newRoofAccess);
                    });
            } else {
                newRoofSection.addEdgeAttachementId(edgeAttachment.id);
            }
        });
    }

    private static addProductToEntities(product: Product, entity: Plan | RoofSection | GuardSystem | RailSystem) {
        entity.addProductToGroup(product);
    }

    private setRailsItems(newPlan: Plan) {
        this.rails?.data?.guardRails.forEach((guardRail: GuardRail) => {
            let onRoof = false;

            if (guardRail.type.startsWith("SG2-DOME-")) {
                const anchoragepoint: AnchoragePoint = Object.create(null);
                const position: Position = Object.create(null);
                position.x = guardRail.location!.origin.x;
                position.y = guardRail.location!.origin.y;
                position.z = guardRail.location!.origin.z;
                anchoragepoint.position = position;
                const onRoofSections = this.getHeighestRoofSection(newPlan, anchoragepoint);

                if (onRoofSections) {
                    if (isPointOnRoofSection(anchoragepoint, onRoofSections.getRoofedges())) {
                        this.setRailOnRoofSection(onRoofSections, guardRail);
                        onRoof = true;
                    }
                }
            }

            guardRail?.corners?.forEach((corner: Corner) => {
                const onRoofSections = this.getHeighestRoofSection(newPlan, corner);
                if (onRoofSections) {
                    const wasAdded = this.railSystemWasAdded(onRoofSections, guardRail.name);
                    if (!wasAdded) {
                        this.setRailOnRoofSection(onRoofSections, guardRail);
                        onRoof = true;
                    }
                }
                if (!onRoof) {
                    this.addAdditionsToObject(corner.additions_v2, newPlan);
                }
            });
        });
    }

    private setItems(newPlan: Plan, products: WorldDataProductWithSurfaceRef[], type: ProductType) {
        products.forEach((product: WorldDataProductWithSurfaceRef) => {
            // first try to find the roof by surface reference
            let roofSection = newPlan.getRoofSections().find((roofSection: RoofSection) => {
                return (
                    product.surfaceRef === roofSection.getSurfaceRef() ||
                    roofSection.includesEdgeAttachmentId(product.surfaceRef)
                );
            });

            // when no roof was found by surface reference use the winding number algorithm
            if (!roofSection) {
                const productWithPosition = product as WorldDataProductWithPosition;
                if (!productWithPosition?.position) {
                    roofSection = this.getHeighestRoofSection(newPlan, productWithPosition);
                }
            }

            if (roofSection) {
                this.setItemOnEntity(roofSection, product, type);
            } else {
                this.setItemOnEntity(newPlan, product, type);
            }
        });
    }

    private setSystemItems(newPlan: Plan, worldDataSystems: WorldDataSystem[], type: ProductType) {
        worldDataSystems.forEach((worldDataSystem: WorldDataSystem) => {
            const roofSection = newPlan.getRoofSections().find((roofSection: RoofSection) => {
                return worldDataSystem.surfaceRef === roofSection.getSurfaceRef();
            });

            if (!roofSection) {
                return;
            }

            const newSystem = new System(worldDataSystem.name, roofSection, type);
            roofSection.setSystems(roofSection.getSystems().concat(newSystem));

            worldDataSystem.products.forEach((worldDataProduct: WorldDataProduct) => {
                let product = JSONHandler.createProductFromWorldDataProduct(worldDataProduct, roofSection!, type);
                product.setPartOf(roofSection?.getPlan().getTitle() + " " + newSystem.getName());
                newSystem.getProducts().push(product);
            });
        });
    }

    private setFallProtectionItems(newPlan: Plan) {
        this.fallprotection?.data.anchoragePoints.forEach((anchorPoint: AnchoragePoint, index: number) => {
            const roofSection = this.getHeighestRoofSection(newPlan, anchorPoint);

            if (roofSection) {
                this.setAnchoragepointOnRoof(index, roofSection!, anchorPoint);
            } else {
                this.addProductToEntity(anchorPoint, newPlan);
            }
        });
    }

    private getHeighestRoofSection(newPlan: Plan, anchorPoint: AnchoragePoint | Corner | WorldDataProductWithPosition) {
        const roofSections = newPlan.getRoofSections().filter((FERoofSection: RoofSection) => {
            return isPointOnRoofSection(anchorPoint, FERoofSection.getRoofedges());
        });
        return roofSections.find(
            (roofSection: RoofSection) =>
                roofSection.getHeight() == Math.max(...roofSections.map((item) => item.getHeight())),
        );
    }

    private getGuardSystemByAnchorpointIndex(index: number): string {
        let productIndex: number[] = [];
        let guardSystemId: string = "";
        this.fallprotection?.data.edgeSystems.forEach((edgeSystem) => {
            productIndex = [...new Set(edgeSystem.anchoragePointRefs)];
            edgeSystem.edges.forEach((edge) => {
                productIndex = productIndex.concat(edge.virtualPointIndices);
            });
            if (productIndex.includes(index)) {
                guardSystemId = edgeSystem.id;
            }
        });
        return guardSystemId;
    }

    private setPlans() {
        const project: OrderProject = new OrderProject("", this.lockBookDrawProjectResponse.id);
        this.setProjectInfo(project);
        const plans: Plan[] = this.lockBookDrawProjectResponse.plans.reduce((plans: Plan[], plan: any) => {
            const newPlan = new Plan(plan.id, plan.title, []);
            // set plan roofSections
            this.setDataStructures(plan);
            this.concreteSurfaces = this.roofDataSet?.data?.surfaces?.filter(
                (surface: Surface) => surface.type === 1 || surface.type === 7,
            );
            if (this.concreteSurfaces) {
                newPlan.setRoofSections(this.reduceRoofSection(newPlan)!);
            }
            if (this.fallprotection) {
                this.setFallProtectionItems(newPlan);
            }
            if (this.rails) {
                this.setRailsItems(newPlan);
            }
            if (this.drainageProducts) {
                this.setItems(newPlan, this.drainageProducts, ProductType.DRAINAGE);
            }
            if (this.vapourBarrierProducts) {
                this.setItems(newPlan, this.vapourBarrierProducts, ProductType.VAPOUR_BARRIER);
            }
            if (this.sealingProducts) {
                this.setItems(newPlan, this.sealingProducts, ProductType.SEALING);
            }
            if (this.primerProducts) {
                this.setItems(newPlan, this.primerProducts, ProductType.PRIMER);
            }
            if (this.bondingProducts) {
                this.setItems(newPlan, this.bondingProducts, ProductType.BONDING);
            }
            if (this.insulationProducts) {
                this.setItems(newPlan, this.insulationProducts, ProductType.INSULATION);
            }
            if (this.pvProducts) {
                this.setItems(newPlan, this.pvProducts, ProductType.PV);
            }
            if (this.moveableRailingSystems) {
                this.setSystemItems(newPlan, this.moveableRailingSystems, ProductType.MOVEABLE_RAILING);
            }
            plans.push(newPlan);
            this.emptyDataStructure();
            return plans;
        }, []);
        project.setObjectplans(plans);
        return project;
    }

    private getGuardSystemTypeById(guardSystemId: string): string {
        const guardSystem = this.fallprotection?.data.edgeSystems.find(
            (guardsystem) => guardsystem.id == guardSystemId,
        );
        if (guardSystem?.type) {
            return guardSystem.type;
        } else {
            return "";
        }
    }

    private getGuardSystemNameById(guardSystemId: string): string {
        const guardSystem = this.fallprotection?.data.edgeSystems.find(
            (guardsystem) => guardsystem.id == guardSystemId,
        );
        if (guardSystem?.name) {
            return guardSystem.name;
        } else {
            return "";
        }
    }

    private checkGuardSystemAlreadyExists(plan: Plan, guardSystemId: string): boolean {
        const exists = false;
        plan?.getRoofSections().forEach((roofsection) => {
            roofsection.getGuardSystems().forEach((gm) => {
                if (gm.getId() == guardSystemId) {
                    return true;
                }
            });
        });
        return exists;
    }

    private getGeneratedAttachementOfGuardSystems(guardSystemId: string): GeneratedAddition2[] | undefined {
        const guardsystem = this.fallprotection?.data.edgeSystems.find(
            (guardsystem) => guardsystem.id == guardSystemId,
        );
        // @ts-ignore
        return guardsystem?.additions_v2?.generatedAdditions?.filter((generatedAddition: GeneratedAddition2) => {
            return !guardsystem?.additions_v2?.deletedGeneratedAdditions?.includes(generatedAddition.id);
        });
    }

    private getUserAdditionsOfGuardSystems(guardSystemId: string): GeneratedAddition2[] | undefined {
        const guardsystem = this.fallprotection?.data.edgeSystems.find(
            (guardsystem) => guardsystem.id == guardSystemId,
        );
        // @ts-ignore
        return guardsystem?.additions_v2?.userAdditions?.filter((generatedAddition: GeneratedAddition2) => {
            return !guardsystem?.additions_v2?.deletedGeneratedAdditions?.includes(generatedAddition.id);
        });
    }

    private getAttachementOfGuardSystems(guardSystemId: string): GeneratedAddition2[] | undefined {
        const guardsystem = this.fallprotection?.data.edgeSystems.find(
            (guardsystem) => guardsystem.id == guardSystemId,
        );
        // @ts-ignore
        return guardsystem?.additions?.filter((generatedAddition: Addition) => {
            return !guardsystem?.additions_v2?.deletedGeneratedAdditions?.includes(generatedAddition.id);
        });
    }

    private addProductToEntity(
        anchorPoint: AnchoragePoint,
        entity: Plan | RoofSection | GuardSystem | RailSystem,
        type?: ProductType,
    ) {
        if (anchorPoint.product) {
            const product = JSONHandler.createProduct(anchorPoint.product, entity);
            const productType = type ? type : JSONHandler.getProductType(entity, anchorPoint);
            product.isEnforced = anchorPoint.isEnforced;
            product.setType(productType);
            JSONHandler.addProductToEntities(product, entity);
            this.addAdditionsToObject(anchorPoint.additions, entity, productType);
        }
    }

    private addAdditionsToObject<T extends Additions | AdditionsV2>(
        item: T,
        entity: Plan | RoofSection | GuardSystem | RailSystem,
        type?: ProductType,
    ): void {
        const deletedGeneratedAdditions = item?.deletedGeneratedAdditions;
        item?.userAdditions
            ?.filter((userAddition: UserAddition) => !deletedGeneratedAdditions?.includes(userAddition.id))
            .reduce((userAdditions: UserAddition[], userAddition: UserAddition) => {
                const createdProduct: Product = JSONHandler.createProduct(userAddition.product, entity);
                type != undefined ? createdProduct.setType(type) : null;
                this.addNewProductToEntities(createdProduct, entity);
                return userAdditions;
            }, []);

        const uniqueGeneratedAdditions = new Set(
            item?.generatedAdditions?.filter(
                (generatedAddition: GeneratedAddition) =>
                    !deletedGeneratedAdditions?.includes(generatedAddition.id) &&
                    // ITS-1337 stop importing SG2-ROHR-2500 and SG2-VB
                    generatedAddition.product.id !== "SG2-ROHR-2500" &&
                    generatedAddition.product.id !== "SG2-VB",
            ),
        );

        Array.from(uniqueGeneratedAdditions).reduce(
            (
                generatedAdditions: (GeneratedAddition | GeneratedAddition2)[],
                generatedAddition: GeneratedAddition | GeneratedAddition2,
            ) => {
                const createdProduct: Product = JSONHandler.createProduct(generatedAddition.product, entity);
                type != undefined ? createdProduct.setType(type) : null;
                this.addNewProductToEntities(createdProduct, entity);
                return generatedAdditions;
            },
            [],
        );
    }

    private addAnchoragePointToGuardSystem(guardSystem: GuardSystem, anchorPoint: AnchoragePoint): void {
        const type =
            guardSystem.getType() == RoofSectionType.TEMPORARY_CABLE
                ? ProductType.STANDALONE
                : anchorPoint.isEnforced
                  ? ProductType.EDGE
                  : ProductType.INTERMEDIATE;

        if (!anchorPoint.product) {
            this.addAdditionsToGuardSystem(anchorPoint, guardSystem, true, type);
            return;
        }

        const product = JSONHandler.createProduct(anchorPoint.product, guardSystem!);
        product.isEnforced = anchorPoint.isEnforced;

        product.setType(type);

        guardSystem?.addProductToGroup(product);
        if (guardSystem.getType() == RoofSectionType.TEMPORARY_CABLE) {
            guardSystem?.getRoofSection().addProductToGroup(product, true);
            product.setInSystem(true);
        } else {
            guardSystem?.getRoofSection().addProductToGroup(product);
        }

        this.addAdditionsToGuardSystem(anchorPoint, guardSystem, product.getInSystem(), product.getType());
    }

    private addNewGuardSystemToRoofSection(
        guardSystemId: string,
        roofSection: RoofSection,
        anchorPoint: AnchoragePoint,
    ): void {
        const newGuardSystem = new GuardSystem(
            guardSystemId,
            roofSection,
            this.getGuardSystemNameById(guardSystemId),
            this.getGuardSystemTypeById(guardSystemId),
        );
        newGuardSystem.setName(this.getGuardSystemNameById(guardSystemId));

        if (!this.checkGuardSystemAlreadyExists(roofSection.getPlan(), guardSystemId)) {
            const guardSystemGeneratedAttachments = this.getGeneratedAttachementOfGuardSystems(guardSystemId);
            if (guardSystemGeneratedAttachments) {
                for (const guardSystemAttachment of guardSystemGeneratedAttachments) {
                    //@ts-ignore
                    this.addProductToEntity(guardSystemAttachment, newGuardSystem, ProductType.ADDITION);
                }
            }
            const guardSystemAttachments = this.getAttachementOfGuardSystems(guardSystemId);
            if (guardSystemAttachments) {
                for (const guardSystemAttachment of guardSystemAttachments) {
                    //@ts-ignore
                    this.addProductToEntity(guardSystemAttachment, newGuardSystem, ProductType.ADDITION);
                }
            }
            const guardSystemUserAdditions = this.getUserAdditionsOfGuardSystems(guardSystemId);
            guardSystemUserAdditions?.forEach((guardSystemAttachment) => {
                //@ts-ignore
                this.addProductToEntity(guardSystemAttachment, newGuardSystem, ProductType.ADDITION);
            });
        }
        this.addAnchoragePointToGuardSystem(newGuardSystem, anchorPoint);

        // get guardsystem attachments
        roofSection.setGuardSystems(roofSection.getGuardSystems().concat(newGuardSystem));
    }

    private addAdditionsToGuardSystem(
        anchorPoint: AnchoragePoint,
        guardSystem: GuardSystem,
        inSystem: boolean,
        type: ProductType,
    ): void {
        const deletedGeneratedAdditions = anchorPoint.additions?.deletedGeneratedAdditions;

        anchorPoint?.additions?.generatedAdditions
            ?.filter(
                (generatedAddition: GeneratedAddition) => !deletedGeneratedAdditions?.includes(generatedAddition.id),
            )
            .reduce((generatedAdditions: GeneratedAddition[], addition: GeneratedAddition) => {
                const addProduct = JSONHandler.createProduct(addition.product, guardSystem!);
                addProduct.setInSystem(inSystem);

                addProduct.setType(addProduct.getName().startsWith("SY") ? ProductType.ADDITION : type);
                guardSystem?.addProductToGroup(addProduct);
                guardSystem?.getRoofSection().addProductToGroup(addProduct);
                return generatedAdditions;
            }, []);

        anchorPoint?.additions?.userAdditions
            ?.filter((userAddition: UserAddition) => !deletedGeneratedAdditions?.includes(userAddition.id))
            .reduce((userAdditions: UserAddition[], addition: UserAddition) => {
                const addProduct = JSONHandler.createProduct(addition.product, guardSystem!);
                addProduct.setInSystem(inSystem);
                addProduct.setType(addProduct.getName().startsWith("SY") ? ProductType.ADDITION : type);
                guardSystem?.addProductToGroup(addProduct);
                guardSystem?.getRoofSection().addProductToGroup(addProduct);
                return userAdditions;
            }, []);
    }

    private setProjectInfo(project: OrderProject): void {
        project.setDescription(this.lockBookDrawProjectResponse.description);
        project.setShortId(this.lockBookDrawProjectResponse.shortId);
    }

    private setDataStructures(plan: any) {
        if (plan.data == undefined) {
            errorStore.addErrorMessage("Plan kann nicht geladen werden, API daten inkorrekt");
        }
        this.fallprotection = plan.data.find((dataset: FallProtectionDataSet) => dataset.type === "fallProtection");
        this.rails = plan.data.find((dataset: RailingDataSet) => dataset.type === "railing");
        this.roofDataSet = plan.data.find((dataset: RoofDataSet) => dataset.type === "roof");
        this.roofAccesses = plan.data.filter((dataset: RoofDataSet) => dataset.type === "roofAccess");

        const worldData = plan.data.find((dataset: WorldDataSet) => dataset.type === "world");
        if (worldData) {
            this.drainageProducts = this.filterForDrainageProducts(worldData?.data?.entities);
            this.insulationProducts = this.filterForInsulationProducts(worldData?.data?.entities);
            this.vapourBarrierProducts = this.filterForVapourBarrierProducts(worldData?.data?.entities);
            this.sealingProducts = this.filterForSealingProducts(worldData?.data?.entities);
            this.primerProducts = this.filterForPrimerProducts(worldData?.data?.entities);
            this.bondingProducts = this.filterForBondingProducts(worldData?.data?.entities);
            this.pvProducts = this.filterForPvProducts(worldData?.data?.entities);
            this.moveableRailingSystems = this.filterForMoveableRailingProducts(worldData?.data?.entities);
        }
    }

    private filterForBondingProducts(worldDataEntities: any) {
        const bondingParents = new Map(
            Object.entries<WorldDataBondingParent>(worldDataEntities).filter(
                ([, v]) =>
                    (v as WorldDataBondingParent)?.components?.POSITION_FIXING_BONDING?.type ===
                    "POSITION_FIXING_BONDING",
            ),
        );

        const bondingArticles = Object.entries<WorldDataProduct>(worldDataEntities).filter(([, v]) => {
            const worldDataProduct = v as WorldDataProduct;

            const potentialParent = bondingParents.get(worldDataProduct?.components?.PARENT_REF?.value);

            if (potentialParent === undefined) {
                return false;
            }

            return (
                potentialParent.components.POSITION_FIXING_BONDING.value.selectedProduct ===
                worldDataProduct.components.PRODUCT.value
            );
        });

        const bondingArticlesWithSurfaceRef = bondingArticles.map((bondingArticleEntry: [string, WorldDataProduct]) => {
            const bondingArticle = bondingArticleEntry[1];
            const parentId = bondingArticle.components.PARENT_REF.value;

            const surfaceRef = bondingParents.get(parentId)!.components?.ROOF_SURFACE_REF?.value;

            const bondingArticleWithSurfaceRef: WorldDataProductWithSurfaceRef = {
                ...bondingArticle,
                surfaceRef: surfaceRef,
            };

            return bondingArticleWithSurfaceRef;
        });

        return bondingArticlesWithSurfaceRef;
    }

    private filterForPrimerProducts(worldDataEntities: any) {
        const primerParents = new Map(
            Object.entries<WorldDataPrimerParent>(worldDataEntities).filter(
                ([, v]) =>
                    (v as WorldDataPrimerParent)?.components?.POSITION_FIXING_PRIMER?.type === "POSITION_FIXING_PRIMER",
            ),
        );

        const primerArticles = Object.entries<WorldDataProduct>(worldDataEntities).filter(([, v]) => {
            const worldDataProduct = v as WorldDataProduct;

            const potentialParent = primerParents.get(worldDataProduct?.components?.PARENT_REF?.value);

            if (potentialParent === undefined) {
                return false;
            }

            return (
                potentialParent.components.POSITION_FIXING_PRIMER.value.selectedProduct ===
                worldDataProduct.components.PRODUCT.value
            );
        });

        const primerArticlesWithSurfaceRef = primerArticles.map((primerArticleEntry: [string, WorldDataProduct]) => {
            const primerArticle = primerArticleEntry[1];
            const parentId = primerArticle.components.PARENT_REF.value;

            const surfaceRef = primerParents.get(parentId)!.components?.ROOF_SURFACE_REF?.value;

            const primerArticleWithSurfaceRef: WorldDataProductWithSurfaceRef = {
                ...primerArticle,
                surfaceRef: surfaceRef,
            };

            return primerArticleWithSurfaceRef;
        });

        return primerArticlesWithSurfaceRef;
    }

    private filterForSealingProducts(worldDataEntities: any) {
        const sealingParents = new Map(
            Object.entries<WorldDataSealingParent>(worldDataEntities).filter(
                ([, v]) => (v as WorldDataSealingParent)?.components?.BUILDING_PHYSICS_LAYER?.value?.type === "SEALING",
            ),
        );

        const sealingArticles = Object.entries<WorldDataProduct>(worldDataEntities).filter(([, v]) => {
            const worldDataProduct = v as WorldDataProduct;
            return (
                sealingParents.has(worldDataProduct?.components?.PARENT_REF?.value) &&
                worldDataProduct?.components?.PRODUCT?.value
            );
        });

        const sealingArticlesWithSurfaceRef = sealingArticles.map((sealingArticleEntry: [string, WorldDataProduct]) => {
            const sealingArticle = sealingArticleEntry[1];
            const parentId = sealingArticle.components.PARENT_REF.value;

            const surfaceRef = sealingParents.get(parentId)!.components?.ROOF_SURFACE_REF?.value;

            const sealingArticleWithSurfaceRef: WorldDataProductWithSurfaceRef = {
                ...sealingArticle,
                surfaceRef: surfaceRef,
            };

            return sealingArticleWithSurfaceRef;
        });

        return sealingArticlesWithSurfaceRef;
    }

    private filterForVapourBarrierProducts(worldDataEntities: any) {
        const vapourBarrierParents = new Map(
            Object.entries<WorldDataVapourBarrierParent>(worldDataEntities).filter(
                ([, v]) =>
                    (v as WorldDataVapourBarrierParent)?.components?.BUILDING_PHYSICS_LAYER?.value?.type ===
                    "VAPOUR_BARRIER",
            ),
        );

        const vapourBarrierArticles = Object.entries<WorldDataProduct>(worldDataEntities).filter(([, v]) => {
            const worldDataProduct = v as WorldDataProduct;
            return (
                vapourBarrierParents.has(worldDataProduct?.components?.PARENT_REF?.value) &&
                worldDataProduct?.components?.PRODUCT?.value
            );
        });

        const vapourBarrierArticlesWithPosition = vapourBarrierArticles.map(
            (vapourBarrierArticleEntry: [string, WorldDataProduct]) => {
                const vapourBarrierArticle = vapourBarrierArticleEntry[1];
                const parentId = vapourBarrierArticle.components.PARENT_REF.value;

                const surfaceRef = vapourBarrierParents.get(parentId)!.components?.ROOF_SURFACE_REF?.value;

                const vapourBarrierArticleWithSurfaceRef: WorldDataProductWithSurfaceRef = {
                    ...vapourBarrierArticle,
                    surfaceRef: surfaceRef,
                };

                return vapourBarrierArticleWithSurfaceRef;
            },
        );

        return vapourBarrierArticlesWithPosition;
    }

    private filterForDrainageProducts(worldDataEntities: any) {
        const drainageParents = new Map(
            Object.entries<WorldDataDrainageParent>(worldDataEntities).filter(([, v]) => {
                return (
                    (v as WorldDataDrainageParent)?.components?.DRAINAGE?.type ||
                    (v as WorldDataDrainageParent)?.components?.EDGE_DRAINAGE?.type
                );
            }),
        );

        const drainageArticles = Object.entries<WorldDataProduct>(worldDataEntities).filter(([, v]) => {
            const worldDataProduct = v as WorldDataProduct;
            return (
                drainageParents.has(worldDataProduct?.components?.PARENT_REF?.value) &&
                worldDataProduct?.components?.PRODUCT?.value
            );
        });

        const drainageArticlesWithPosition = drainageArticles.map(
            (drainageArticleEntry: [string, WorldDataProduct]) => {
                const drainageArticle = drainageArticleEntry[1];
                const parentId = drainageArticle.components.PARENT_REF.value;

                const parent = drainageParents.get(parentId)!;
                const parentPosition = parent.components.POSITION;
                let drainageArticleWithRef: WorldDataProductWithSurfaceRef | WorldDataProductWithPosition;

                if (parentPosition) {
                    const position = {
                        x: parentPosition.value.x,
                        y: parentPosition.value.y,
                        z: parentPosition.value.z,
                    };
                    const surfaceRef = parent?.components?.ROOF_SURFACE_REF?.value
                        ? parent.components.ROOF_SURFACE_REF.value
                        : "";

                    drainageArticleWithRef = {
                        ...drainageArticle,
                        position: position,
                        surfaceRef: surfaceRef,
                    };
                } else {
                    const surfaceRef = parent.components.SURFACE_EDGE_ATTACHMENT_REF!.value;
                    drainageArticleWithRef = {
                        ...drainageArticle,
                        surfaceRef: surfaceRef,
                    };
                }

                return drainageArticleWithRef;
            },
        );
        return drainageArticlesWithPosition;
    }

    private filterForPvProducts(worldDataEntities: any) {
        const pvParents = new Map(
            Object.entries<WorldDataPvParent>(worldDataEntities).filter(([k, v]) => {
                return (
                    (v as WorldDataPvParent)?.components?.PV_SUBSTRUCTION?.type ||
                    (v as WorldDataPvParent)?.components?.PV_UNDERCONSTRUCTION?.type ||
                    (v as WorldDataPvParent)?.components?.PV_OTHER_COMPONENT?.type ||
                    (v as WorldDataPvParent)?.components?.PV_INVERTER?.type ||
                    (v as WorldDataPvParent)?.components?.PV_ROOF_SETTINGS?.type ||
                    (v as WorldDataPvParent)?.components?.PV_FIELD?.type
                );
            }),
        );

        const pvArticles = Object.entries<WorldDataProduct>(worldDataEntities).filter(([k, v]) => {
            const worldDataProduct = v as WorldDataProduct;
            return (
                pvParents.has(worldDataProduct?.components?.PARENT_REF?.value) &&
                worldDataProduct?.components?.PRODUCT?.value
            );
        });

        const pvArticlesWithPosition = pvArticles.map((pvArticleEntry: [string, WorldDataProduct]) => {
            const pvArticle = pvArticleEntry[1];
            const parentId = pvArticle.components.PARENT_REF.value;

            const parentPosition = pvParents.get(parentId)!.components.PV_SUBSTRUCTION?.value.centerPosition;
            const position = {
                x: parentPosition ? parentPosition.x : 0,
                y: parentPosition ? parentPosition.y : 0,
                z: 0,
            };
            const surfaceRef = pvParents.get(parentId)!.components?.SURFACE_PARENT_REF?.value;

            const pvArticleWithPosition: WorldDataProductWithPosition = {
                ...pvArticle,
                position: position,
                surfaceRef: surfaceRef,
            };

            return pvArticleWithPosition;
        });
        return pvArticlesWithPosition;
    }

    private filterForInsulationProducts(worldDataEntities: any) {
        const insulationParents = new Map(
            Object.entries<WorldDataInsulationParent>(worldDataEntities).filter(([, v]) => {
                return (
                    (v as WorldDataInsulationParent)?.components?.TAPERED_INSULATION?.type ||
                    (v as WorldDataInsulationParent)?.components?.FLAT_INSULATION?.type
                );
            }),
        );

        const insulationArticles = Object.entries<WorldDataProduct>(worldDataEntities).filter(([, v]) => {
            const worldDataProduct = v as WorldDataProduct;
            return (
                insulationParents.has(worldDataProduct?.components?.PARENT_REF?.value) &&
                worldDataProduct?.components?.PRODUCT?.value
            );
        });

        const insulationArticlesWithPosition = insulationArticles.map(
            (insulationArticleEntry: [string, WorldDataProduct]) => {
                const insulationArticle = insulationArticleEntry[1];
                const parentId = insulationArticle.components.PARENT_REF.value;

                const parentPosition = insulationParents.get(parentId)!.components.POSITION_2D;
                const position = { x: parentPosition.value.x, y: parentPosition.value.y, z: parentPosition.value.z };
                const surfaceRef = insulationParents.get(parentId)!.components?.ROOF_SURFACE_REF?.value;

                const insulationArticleWithPosition: WorldDataProductWithPosition = {
                    ...insulationArticle,
                    position: position,
                    surfaceRef: surfaceRef,
                };

                return insulationArticleWithPosition;
            },
        );
        return insulationArticlesWithPosition;
    }

    private filterForMoveableRailingProducts(worldDataEntities: any): WorldDataSystem[] {
        const moveableRailingParents = Object.values<WorldDataMoveableRailingParent>(worldDataEntities)
            .filter((v) => (v as WorldDataMoveableRailingParent)?.components?.MOVEABLE_RAILING?.type)
            .sort((a, b) => a.components.NAME.value.localeCompare(b.components.NAME.value));

        const moveableRailingGroups = moveableRailingParents.map(
            (moveableRailingParent: WorldDataMoveableRailingParent) => {
                const groupName = moveableRailingParent.components.NAME.value;

                const moveableRailingArticles = Object.values<WorldDataProduct>(worldDataEntities).filter((v) => {
                    const worldDataProduct = v as WorldDataProduct;
                    return (
                        moveableRailingParent.id === worldDataProduct?.components?.PARENT_REF?.value &&
                        worldDataProduct?.components?.PRODUCT?.value
                    );
                });

                // get the surface ref
                const moveableRailingAttachementPoint = Object.values<AttachementPointComponent>(
                    worldDataEntities,
                ).find(
                    (v) =>
                        (v as AttachementPointComponent)?.components?.ATTACHMENT_POINT_COMPONENT?.type &&
                        ((v as AttachementPointComponent)?.components?.ATTACHMENT_POINT_COMPONENT.value.joints
                            .bottomLeft?.occupierId === moveableRailingParent.id ||
                            (v as AttachementPointComponent)?.components?.ATTACHMENT_POINT_COMPONENT.value.joints
                                .bottomRight?.occupierId === moveableRailingParent.id ||
                            (v as AttachementPointComponent)?.components?.ATTACHMENT_POINT_COMPONENT.value.joints
                                .topLeft?.occupierId === moveableRailingParent.id ||
                            (v as AttachementPointComponent)?.components?.ATTACHMENT_POINT_COMPONENT.value.joints
                                .topRight?.occupierId === moveableRailingParent.id),
                );

                const surfaceRef = moveableRailingAttachementPoint?.components.SURFACE_PARENT_REF.value;
                return { name: groupName, surfaceRef: surfaceRef, products: moveableRailingArticles };
            },
        );

        return moveableRailingGroups;
    }

    private railSystemWasAdded(roofSection: RoofSection, railSystemId: string) {
        return roofSection
            .getPlan()
            .getRoofSections()
            .some((roofSection: RoofSection) => {
                return roofSection.getRailSystems().some((rs) => {
                    return rs.getId() == railSystemId;
                });
            });
    }
}
