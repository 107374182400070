import { makeAutoObservable } from "mobx";
import { ProductType } from "../../common/models/enums";
import { ExportComment } from "../../common/models/export-comment";
import { ICheck } from "./IChecked";
import { IClassName } from "./IClassName";
import { PartialSum } from "./PartialSum";
import { Product } from "./Product";
import { RoofSection } from "./RoofSection";

export class System implements ICheck, IClassName {
    private _id: string;
    private _name: string;
    private readonly _roofsection: RoofSection;
    private _products: Product[];
    private readonly _comment: ExportComment;
    private _checked: boolean;
    private readonly _partialSum: PartialSum;
    private readonly _productType: ProductType;

    constructor(name: string, roofSection: RoofSection, productType: ProductType) {
        makeAutoObservable(this);
        this._id = name;
        this._name = name;
        this._roofsection = roofSection;
        this._products = [];
        this._comment = new ExportComment(this.getName());
        this._checked = true;
        this._partialSum = new PartialSum(this);
        this._productType = productType;
    }
    getClassName(): string {
        return "SYSTEM";
    }
    toggle(): void {
        this.setChecked(!this.getChecked());

        if (!this.getRoofSection().getChecked()) {
            this.getRoofSection().setChecked(true);
        }
        if (!this.getRoofSection().getPlan().getChecked()) {
            this.getRoofSection().getPlan().setChecked(true);
        }
    }

    getChecked(): boolean {
        return this._checked;
    }

    setChecked(checked: boolean): void {
        this._checked = checked;
    }

    getName(): string {
        return this._name;
    }

    getRoofSection(): RoofSection {
        return this._roofsection;
    }

    getProducts(): Product[] {
        return this._products;
    }

    getId() {
        return this._id;
    }

    getComment() {
        return this._comment;
    }

    getPartialSum() {
        return this._partialSum;
    }

    getProductType() {
        return this._productType;
    }
}
