import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorModal from "../../components/modals/ErrorModal";
import { ImporterType } from "../../models/enums";
import ImportSection from "./ImportSection";
import OptionSection from "./OptionSection";
import ResultSection from "./ResultSection";
import image from "./ssloading.png";

const determineImporterByProjectIdPrefix = (projectId: string | undefined) => {
    if (projectId === undefined) {
        return ImporterType.DRAW;
    }
    if (projectId.startsWith(ImporterType.LADDER_CONFIG)) {
        return ImporterType.LADDER_CONFIG;
    }
    if (projectId.startsWith(ImporterType.TENDERFIX)) {
        return ImporterType.TENDERFIX;
    }
    return ImporterType.DRAW;
};

const Home: React.FC<{
    importer?: ImporterType;
}> = (props) => {
    const [importer, setImporter] = useState(props.importer);
    const params = useParams();

    useEffect(() => {
        if (importer === undefined) {
            setImporter(determineImporterByProjectIdPrefix(params.projectId));
        }
    }, [params.projectId, importer]);

    return (
        <div className="Container">
            <div className="WarningBar">
                <h1>Hinweis</h1>
                <p>
                    Das Angebot in Selectline darf sich nicht im Bearbeitungsstatus befinden! (
                    <img src={image} height={20} width={20} alt="reload" /> darf nicht ausgegraut sein )
                </p>
            </div>

            <ErrorModal />

            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item sm={3}>
                    <ImportSection importer={importer} setImporter={setImporter} />
                </Grid>
                <Grid item sm={3}>
                    <OptionSection importer={importer} />
                </Grid>
                <Grid item sm={6}>
                    <ResultSection importer={importer} />
                </Grid>
            </Grid>
        </div>
    );
};
export default Home;
