import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import TextFieldModal from "../../common/components/modals/TextFieldModal";
import commentDialogStore from "../../common/stores/CommentDialogStore";
import { SortProductsBy } from "../models/enums";
import { default as projectStore } from "../stores/ProjectStore";
import OrderOptionSection from "./OrderOptionSection";

function ResultDrawSection() {
    return (
        <div className="resultComponent, componentContainer, resultTable">
            {commentDialogStore.getShowDialog() && (
                <TextFieldModal
                    comment={commentDialogStore.getComment()}
                    onClickFunc={() => {
                        commentDialogStore.toggleShowDialog();
                    }}
                />
            )}
            <OrderOptionSection />
            <TableContainer className="testTableContainer" component={Paper} style={{ marginTop: 50 }}>
                <Table sx={{ minWidth: "90%", maxWidth: "100%" }} stickyHeader aria-label="sticky table">
                    <TableHead style={{ zIndex: -1 }}>
                        <TableRow key={"headerRow"}>
                            <TableCell
                                style={{
                                    backgroundColor: "#f9f7f3",
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                }}
                                align="center"
                            >
                                Produkt
                            </TableCell>
                            <TableCell
                                style={{
                                    backgroundColor: "#f9f7f3",
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                }}
                                align="right"
                            >
                                Produkt Typ
                            </TableCell>
                            <TableCell
                                style={{
                                    backgroundColor: "#f9f7f3",
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                }}
                                align="right"
                            >
                                Anzahl
                            </TableCell>
                            <TableCell
                                style={{
                                    backgroundColor: "#f9f7f3",
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                }}
                                align="right"
                            >
                                {projectStore.getSortOn() === SortProductsBy.PLAN
                                    ? "Plan"
                                    : projectStore.getSortOn() === SortProductsBy.ROOFSECTION
                                      ? "Dachfläche"
                                      : "System"}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{projectStore.returnResponse()}</TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
export default observer(ResultDrawSection);
