import { observer } from "mobx-react";
import React from "react";
import OptionDrawSection from "../../../draw/components/OptionDrawSection";
import OptionLadderSection from "../../../ladder-config/components/OptionLadderSection";
import { ImporterType } from "../../models/enums";

type OptionSectionProps = {
    importer: string | undefined;
};

const OptionSection: React.FC<OptionSectionProps> = (props) => {
    switch (props.importer) {
        case ImporterType.LADDER_CONFIG:
            return <OptionLadderSection />;
        case ImporterType.TENDERFIX:
            return <div style={{ overflow: "auto" }} className="optionComponent, componentContainer"></div>;
        default:
            return <OptionDrawSection />;
    }
};

export default observer(OptionSection);
