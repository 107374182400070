import { DropdownWithButton, IconArea, IconBuilding, IconPlaceholder } from "@abs-safety/lock-book-web-ui";
import Grid from "@mui/material/Grid";
import { observer } from "mobx-react";
import React from "react";
import ExportButton from "../../common/components/ExportButton";
import SubOptionItem from "../../common/components/SubOptionItem";
import { SortProductsBy } from "../models/enums";
import projectStore from "../stores/ProjectStore";

function OrderOptionSection() {
    return (
        <div className="subComponentContainer">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item sm={4}>
                    <SubOptionItem
                        checked={projectStore.getPartSum()}
                        name={"Teilsumme(n) anzeigen"}
                        onClickFunc={() => {
                            projectStore.togglePartSum();
                        }}
                    />
                    <SubOptionItem
                        checked={projectStore.getComments()}
                        name={"Kommentar(e) anzeigen"}
                        toolTipText={
                            "Der Kommentar kann bearbeitet werden, wenn Sie 'Kommentar' drücken. Wenn Sie den Kommentar wieder leeren, wird der Informationstext gespeichert"
                        }
                        onClickFunc={() => {
                            projectStore.toggleComments();
                        }}
                    />
                </Grid>
                <Grid style={{ display: "flex", justifyContent: "center", alignItems: "center" }} item sm={4}>
                    <div style={{ paddingTop: 10, zIndex: 9 }}>
                        <DropdownWithButton
                            buttonColor={"black"}
                            buttonText="Sortieren nach"
                            items={[
                                {
                                    checkmark: projectStore.getSortOn() === SortProductsBy.PLAN,
                                    icon: <IconPlaceholder />,
                                    onClick: () => projectStore.setSortOn(SortProductsBy.PLAN),
                                    text: "Plan",
                                },
                                {
                                    checkmark: projectStore.getSortOn() === SortProductsBy.ROOFSECTION,
                                    icon: <IconBuilding />,
                                    onClick: () => projectStore.setSortOn(SortProductsBy.ROOFSECTION),
                                    text: "Dachfläche",
                                },
                                {
                                    checkmark: projectStore.getSortOn() === SortProductsBy.GUARDSYSTEM,
                                    icon: <IconArea />,
                                    onClick: () => projectStore.setSortOn(SortProductsBy.GUARDSYSTEM),
                                    text: "System",
                                },
                            ]}
                            width={100}
                        />
                    </div>
                </Grid>
                <Grid item sm={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <ExportButton onClick={() => projectStore.saveOrder()} />
                </Grid>
            </Grid>
        </div>
    );
}

export default observer(OrderOptionSection);
