import { makeAutoObservable } from "mobx";
import { ProductType } from "../../common/models/enums";
import { ExportComment } from "../../common/models/export-comment";
import projectStore from "../stores/ProjectStore";
import { productIsStackable, productTypeFormsGroup, sortProductGroup } from "../utilities/Functions";
import { SortProductsBy } from "./enums";
import { GuardSystem } from "./GuardSystem";
import { ICheck } from "./IChecked";
import { IClassName } from "./IClassName";
import { DrawLine } from "./interfaces";
import { PartialSum } from "./PartialSum";
import { Plan } from "./Plan";
import { Product } from "./Product";
import { ProductGroup } from "./ProductGroup";
import { RailSystem } from "./RailSystem";
import { RoofAccess } from "./RoofAccess";
import { System } from "./System";

export class RoofSection implements RoofSection, ICheck, IClassName {
    private _id: string;
    private _guardSystems: GuardSystem[];
    private _checked: boolean;
    private _roofedges: DrawLine[];
    private _name: string | undefined;
    private readonly _plan: Plan;
    private readonly _classname: string;
    private readonly _surface: string;
    private _productGroups: ProductGroup[];
    private _comment: ExportComment;
    private readonly _partialSum: PartialSum;
    private _railSystems: RailSystem[];

    private _systems: System[];
    private _roofAccesses: RoofAccess[] = [];
    private _height: number;
    private _surfaceRef: string;
    private _edgeAttachementIds: string[] = [];

    constructor(id: string, plan: Plan, surface: string, height: number, surfaceRef: string) {
        makeAutoObservable(this);
        this._id = id;
        this._checked = true;
        this._guardSystems = [];
        this._roofedges = [];
        this._plan = plan;
        this._classname = "ROOFSECTION";
        this._surface = surface;
        this._productGroups = [];
        Object.keys(ProductType).forEach((key: string) => {
            this._productGroups.push(new ProductGroup(key, this));
        });
        this._comment = new ExportComment(id);
        this._partialSum = new PartialSum(this);
        this._railSystems = [];
        this._systems = [];
        this._roofAccesses = [];
        this._edgeAttachementIds = [];
        this._height = height;
        this._surfaceRef = surfaceRef;
    }
    getClassName(): string {
        return this._classname;
    }

    toggle(): void {
        if (!this.getPlan().getChecked()) {
            this.getPlan().setChecked(true);
        }
        this._checked = !this._checked;
        this.getProductGroups().forEach((productGroup) => productGroup.setChecked(this.getChecked()));
        this.getGuardSystems().forEach((guardSystem) => guardSystem.setChecked(this.getChecked()));
        this.getRoofAccesses().forEach((roofAccess) => roofAccess.setChecked(this.getChecked()));
        this.getRailSystems().forEach((railSystem) => railSystem.setChecked(this.getChecked()));
        this.getSystems().forEach((system) => system.setChecked(this.getChecked()));
    }

    getHeight(): number {
        return this._height;
    }

    getSurfaceRef(): string {
        return this._surfaceRef;
    }

    getSurface(): string {
        return this._surface;
    }

    getId(): string {
        return this._id;
    }

    setId(id: string): void {
        this._id = id;
    }

    getProductGroups(): ProductGroup[] {
        return this._productGroups;
    }

    getGuardSystems(): GuardSystem[] {
        return this._guardSystems;
    }

    getRailSystems(): RailSystem[] {
        return this._railSystems;
    }

    setRoofAccesses(roofAccesses: RoofAccess[]): void {
        this._roofAccesses = roofAccesses;
    }

    getRoofAccesses(): RoofAccess[] {
        return this._roofAccesses;
    }

    setRailSystems(railSystems: RailSystem[]): void {
        this._railSystems = railSystems;
    }

    getCheckedGuardSystems(): GuardSystem[] {
        return this.getGuardSystems().filter((gs) => gs.getChecked());
    }

    getCheckedProductGroups(): ProductGroup[] {
        return this.getProductGroups().filter((pg) => pg.getChecked());
    }

    getCheckedRailSystems(): RailSystem[] {
        return this.getRailSystems().filter((rs) => rs.getChecked());
    }

    getCheckedRoofAccesses(): RoofAccess[] {
        return this.getRoofAccesses().filter((ra) => ra.getChecked());
    }

    setGuardSystems(guardSystems: GuardSystem[]): void {
        this._guardSystems = guardSystems;
    }

    setProductGroups(productGroups: ProductGroup[]): void {
        this._productGroups = productGroups;
    }

    getChecked(): boolean {
        return this._checked;
    }

    setChecked(checked: boolean): void {
        this._checked = checked;
    }

    getRoofedges(): DrawLine[] {
        return this._roofedges;
    }

    setRoofedges(roofedges: DrawLine[]): void {
        this._roofedges = roofedges;
    }

    getName(): string | undefined {
        return this._name;
    }

    setName(name: string | undefined): void {
        this._name = name;
    }

    getPlan(): Plan {
        return this._plan;
    }

    getProductGroupsByType(type: ProductType): ProductGroup {
        return this._productGroups[Object.values(ProductType).indexOf(type)];
    }

    addProductToGroup(product: Product, skip?: boolean): void {
        if (!skip && productTypeFormsGroup(product.getType()) && !product.getInSystem()) {
            const productGroup = this.getProductGroupsByType(product.getType());
            const productListItem = this.findProductById(product, productGroup.getProducts());
            const addValue = product.getAmount();
            if (productListItem != null) {
                productListItem.setAmount(productListItem.getAmount() + addValue);
            } else {
                productGroup.addProduct(product);
            }
        }
    }

    findProductById(pid: Product, products: Product[], idx = 0): null | Product {
        if (idx >= products.length) return null;

        const item = products[idx];

        if (!item) return null;
        if (item.getId() === pid.getId() && pid.getPartOf() === item.getPartOf() && pid.getName() === item.getName()) {
            return item;
        }

        return this.findProductById(pid, products, idx + 1);
    }

    groupProductsByType() {
        const response: JSX.Element[] = [];
        this._productGroups.forEach((productGroup: ProductGroup, index: number) => {
            this.getCheckedGuardSystems().forEach((guardSystem: GuardSystem) => {
                guardSystem.getProductsByProductGroup(productGroup.getType()).forEach((product: Product) => {
                    response.push(product.showItem(index));
                });
            });
            response.push(productGroup.getComment().showItem(index));
        });
        return response;
    }

    getItems() {
        let items: (ExportComment | Product | PartialSum)[] = [];
        this.addComment(items);
        if (projectStore.getSortOn() === SortProductsBy.ROOFSECTION) {
            items = this.getProductsByType(items);
            items = this.getProductsByType(items, ProductType.DRAINAGE);
            items = this.getProductsByType(items, ProductType.INSULATION);
            items = this.getProductsByType(items, ProductType.VAPOUR_BARRIER);
            items = this.getProductsByType(items, ProductType.SEALING);
            items = this.getProductsByType(items, ProductType.PRIMER);
            items = this.getProductsByType(items, ProductType.BONDING);
            items = this.getProductsByType(items, ProductType.PV);
            items = this.groupAllProductsOfAllGuardSystemsInProductGroups(items);
            items = this.groupAllProductsOfAllRailSystemsInProductGroups(items);
            items = this.groupAllProductsOfAllRoofAccessInProductGroups(items);
            items = this.groupAllProductsOfAllSystems(items);

            if (projectStore.getPartSum()) {
                items.push(this._partialSum);
            }
        } else {
            [
                ProductType.STANDALONE,
                ProductType.DRAINAGE,
                ProductType.INSULATION,
                ProductType.VAPOUR_BARRIER,
                ProductType.SEALING,
                ProductType.PRIMER,
                ProductType.BONDING,
                ProductType.PV,
            ].forEach((productType: ProductType) => {
                if (
                    this.getProductGroupsByType(productType).getProducts().length > 0 &&
                    this.getProductGroupsByType(productType).getChecked()
                ) {
                    items.push(this.getProductGroupsByType(productType).getComment());
                    items = items.concat(this.getProductGroupsByType(productType).getProducts());
                    if (projectStore.getPartSum()) {
                        items.push(this.getProductGroupsByType(productType).getPartial());
                    }
                }
            });

            this.getCheckedGuardSystems().forEach((guardSystem: GuardSystem) => {
                const guardSystemItems = guardSystem.getItems();
                if (guardSystemItems.length > 0) {
                    items = items.concat(guardSystemItems);
                }
            });
            this.getCheckedRailSystems().forEach((railSystem: RailSystem) => {
                const railSystemItems = railSystem.getItems();
                if (railSystemItems.length > 0) {
                    items = items.concat(railSystemItems);
                }
            });
            this.getCheckedRoofAccesses().forEach((roofAccess: RoofAccess) => {
                const roofAccessItems = roofAccess.getItems();
                if (roofAccessItems.length > 0) {
                    items = items.concat(roofAccessItems);
                }
            });
            this._systems.forEach((system: System) => {
                if (system.getChecked()) {
                    items.push(system.getComment());
                    items = items.concat(system.getProducts());
                    items.push(system.getPartialSum());
                }
            });
        }

        return items;
    }

    private groupAllProductsOfAllGuardSystemsInProductGroups(items: (Product | ExportComment | PartialSum)[]) {
        this._productGroups.forEach((productGroup: ProductGroup) => {
            let products: Product[] = [];
            this.getCheckedGuardSystems().forEach((guardSystem: GuardSystem) => {
                guardSystem.getProductsByProductGroup(productGroup.getType()).forEach((product: Product) => {
                    this.groupProducts(product, products);
                });
            });
            products = sortProductGroup(products, productGroup.getType());
            if (products.length > 0) {
                items.push(productGroup.getComment());
                items = items.concat(products);
            }
        });
        return items;
    }

    private groupAllProductsOfAllRailSystemsInProductGroups(items: (Product | ExportComment | PartialSum)[]) {
        const productGroup = this._productGroups.find((item) => item.getType() == "RAILING");
        let products: Product[] = [];
        this.getCheckedRailSystems().forEach((railSystem: RailSystem) => {
            railSystem.getProductsByProductGroup(productGroup!.getType()).forEach((product: Product) => {
                this.groupProducts(product, products);
            });
        });
        products = sortProductGroup(products, productGroup!.getType());
        if (products.length > 0) {
            items.push(productGroup!.getComment());
            items = items.concat(products);
        }
        return items;
    }

    private groupAllProductsOfAllRoofAccessInProductGroups(items: (Product | ExportComment | PartialSum)[]) {
        const productGroup = this._productGroups.find((item) => item.getType() == "ROOFACCESS");

        let products: Product[] = [];
        this.getCheckedRoofAccesses().forEach((roofAcces: RoofAccess) => {
            roofAcces.getProductsByProductGroup(productGroup!.getType()).forEach((product: Product) => {
                this.groupProducts(product, products);
            });
        });
        products = sortProductGroup(products, productGroup!.getType());
        if (products.length > 0) {
            items.push(productGroup!.getComment());
            items = items.concat(products);
        }
        return items;
    }

    private groupAllProductsOfAllSystems(items: (Product | ExportComment | PartialSum)[]) {
        let products: Product[] = [];
        let lastProductType: ProductType | undefined;
        this._systems.forEach((system: System) => {
            if (!system.getChecked()) {
                return;
            }

            if (system.getProductType() !== lastProductType) {
                items = items.concat(products);
                products = [];
                items.push(new ExportComment(system.getProductType()));
            }

            lastProductType = system.getProductType();

            system.getProducts().forEach((product) => {
                this.groupProducts(product, products);
            });
        });
        items = items.concat(products);
        return items;
    }

    private groupProducts(product: Product, products: Product[]) {
        if (productIsStackable(product)) {
            this.groupSameProductNamePieces(products, product);
        } else {
            const newProduct = product.clone();
            newProduct.setPartOf(this.getPlan().getTitle());
            products.push(newProduct);
        }
    }

    private groupSameProductNamePieces(products: Product[], product: Product) {
        if (products.findIndex((p) => p.getId() === product.getId()) === -1) {
            const newProduct = product.clone();
            newProduct.setPartOf(this.getPlan().getTitle());
            products.push(newProduct);
        } else {
            products
                .find((p) => p.getId() === product.getId())!
                .setAmount(+products.find((p) => p.getId() === product.getId())!.getAmount() + +product.getAmount());
        }
    }

    private getProductsByType(
        items: (Product | ExportComment | PartialSum)[],
        productType: ProductType = ProductType.STANDALONE,
    ) {
        if (
            this.getProductGroupsByType(productType).getProducts().length > 0 &&
            this.getProductGroupsByType(productType).getChecked()
        ) {
            if (projectStore.getComments()) {
                items.push(this.getProductGroupsByType(productType).getComment());
            }
            items = items.concat(this.getProductGroupsByType(productType).getProducts());
        }
        return items;
    }

    private addComment(items: (ExportComment | Product | PartialSum)[]) {
        if (projectStore.getComments()) {
            items.push(this._comment);
        }
    }

    setAllProductsToAlt() {
        this._productGroups.forEach((group: ProductGroup) => {
            group.getProducts().forEach((product: Product) => {
                product.toggleAlternative();
            });
        });
        this.getRoofAccesses().forEach((roofAcces: RoofAccess) => {
            roofAcces.setAllProductsToAlt();
        });
        this.getGuardSystems().forEach((guardSystem: GuardSystem) => {
            guardSystem.setAllProductsToAlt();
        });
        this.getRoofAccesses().forEach((roofAccess: RoofAccess) => {
            roofAccess.setAllProductsToAlt();
        });
        this.getRailSystems().forEach((railSystem: RailSystem) => {
            railSystem.setAllProductsToAlt();
        });
    }

    getSystems() {
        return this._systems;
    }

    setSystems(systems: System[]) {
        this._systems = systems;
    }

    addEdgeAttachementId(edgeAttachementId: string) {
        this._edgeAttachementIds.push(edgeAttachementId);
    }

    includesEdgeAttachmentId(edgeAttachementId: string) {
        return this._edgeAttachementIds.includes(edgeAttachementId);
    }
}
