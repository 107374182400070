import { LockBookHeader } from "@abs-safety/lock-book-header";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./common/screen/home/Home";

const App = () => {
    return (
        <Router>
            <div className="App">
                <LockBookHeader environment="prod" application="other" />
                <div className="Body">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/LockBookDrawImporter" element={<Home />}>
                            <Route path=":documentKey" element={<Home />}>
                                <Route path=":projectId" element={<Home />} />
                            </Route>
                        </Route>
                        <Route path=":documentKey" element={<Home />}>
                            <Route path=":projectId" element={<Home />} />
                        </Route>
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default App;
