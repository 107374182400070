import { Button, designTheme, IconCross, IconUpload, ProgressCircle } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import errorStore from "../stores/ErrorStore";
import exportStore from "../stores/ExportStore";

type ExportButtonProps = {
    onClick: () => void;
};

const ExportButton: React.FC<ExportButtonProps> = (props) => {
    const [isOnline, setIsOnline] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
            fetch("https://slmobile.absturzsicherung.de/slmobileabsApi/")
                .then((response) => {
                    if (response.ok) {
                        setIsOnline(true);
                    } else {
                        setIsOnline(false);
                    }
                })
                .catch(() => {
                    setIsOnline(false);
                });
        }, 2000); // 2 seconds in milliseconds

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {!exportStore.getIsExporting() && (
                <Button className="button" style={!isOnline ? { backgroundColor: designTheme.color.declineHover } : {}}>
                    <button
                        onClick={() => {
                            props.onClick();
                        }}
                        disabled={errorStore.getLoading() || exportStore.getIsExporting() || !isOnline}
                    >
                        {!isOnline ? "SLUI Server nicht erreichbar" : "Zu Selectline übertragen"}
                        {!isOnline ? (
                            <IconCross height={15} color="#FFFFFF" />
                        ) : (
                            <IconUpload height={15} color="#FFFFFF" />
                        )}
                    </button>
                </Button>
            )}
            {exportStore.getIsExporting() && (
                <div style={{ margin: "10px 0px 0px" }}>
                    <ProgressCircle
                        totalCount={exportStore.getExportProgress().totalCount}
                        documentedCount={exportStore.getExportProgress().documentedCount}
                        lockedCount={exportStore.getExportProgress().lockedCount}
                    />
                </div>
            )}
        </>
    );
};

export default observer(ExportButton);
