import { AnchoragePoint, Corner, DrawLine, WorldDataProductWithPosition } from "../models/interfaces";

export function isPointOnRoofSection(
    anchorPoint: AnchoragePoint | Corner | WorldDataProductWithPosition,
    roofSectionEdges: DrawLine[],
): boolean {
    let windingNumberCounter = 0;

    const polygonEdgeCount = roofSectionEdges.length;

    for (let i = 0; i < polygonEdgeCount; i++) {
        const edgeStart = roofSectionEdges[i];
        const edgeEnd = roofSectionEdges[(i + 1) % polygonEdgeCount];

        const maxY = Math.max(edgeStart.to.y, edgeEnd.to.y);
        const minY = Math.min(edgeStart.to.y, edgeEnd.to.y);

        // Exclude horizontal lines and lines that do not cross the anchor point
        if (edgeStart.to.y === edgeEnd.to.y || maxY < anchorPoint.position.y || minY > anchorPoint.position.y) {
            continue;
        }

        const side = getWhichSideOfTheLine(edgeStart, edgeEnd, anchorPoint);

        if (edgeStart.to.y < edgeEnd.to.y) {
            // Line goes upwards
            if (side < 0) {
                // Point is on the right side
                windingNumberCounter += 1;
            }
        } else {
            // Line goes downwards
            if (side > 0) {
                // Point is on the left side
                windingNumberCounter -= 1;
            }
        }
    }
    /* Even odd winding*/
    return windingNumberCounter % 2 != 0;
}

/*
    >   0: Left side
    ==  0: On the linde
    <   0: Right side
*/
//  Cross Product

function getWhichSideOfTheLine(
    edgeStart: DrawLine,
    edgeEnd: DrawLine,
    anchorPoint: AnchoragePoint | Corner | WorldDataProductWithPosition,
) {
    const yDifference = anchorPoint.position.y - edgeStart.to.y;
    const xDifference = anchorPoint.position.x - edgeStart.to.x;
    return yDifference * (edgeEnd.to.x - edgeStart.to.x) - xDifference * (edgeEnd.to.y - edgeStart.to.y);
}
