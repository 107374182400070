export const PSAProductNames: string[] = [
    "PS-1002",
    "PS-1013-15",
    "PS-1013-23",
    "PS-1013-30",
    "PS-1015",
    "PS-1015-R",
    "PS-1025-150",
    "PS-1025-300",
    "PS-1025-400",
    "PS-1069",
    "PS-BAND-PROFI-100",
    "PS-BL-B-12-BFD",
    "PS-BL-B-2",
    "PS-BL-B-3,5-BFD",
    "PS-BL-B-3,5-DBI",
    "PS-BL-B-6-BFD",
    "PS-BL-B-HB",
    "PS-BL-S-10",
    "PS-BL-S-15",
    "PS-BL-S-20",
    "PS-BL-S-3,5-A",
    "PS-BL-S-30",
    "PS-BL-S-6-A",
    "PS-BL-S-9",
    "PS-COMFORT",
    "PS-COMFORT-WEST",
    "PS-DD-SET-10-T",
    "PS-DD-SET-15-T",
    "PS-DD-SET-20-T",
    "PS-DD-SET-5-T",
    "PS-DD-SET-PRO-T",
    "PS-FP-SET-TASCHE",
    "PS-HELM-PROFI",
    "PS-KARA-6",
    "PS-KARA-TRI-ST-26",
    "PS-KARA-TWI-AL-24",
    "PS-KARA-TWI-ST-20",
    "PS-ROLL",
    "PS-ST-100",
    "PS-ST-150",
    "PS-ST-200",
    "PS-ST-250",
    "PS-ST-300",
    "PS-STRAP",
    "PS-TELE-10",
    "PS-TELE-3",
    "PS-TELE-4,5",
    "PS-TELE-6",
    "PS-TELE-8",
    "PS-VB-1.0",
    "PS-VB-1.5",
    "PS-VB-1.5-RH",
    "PS-VB-2.0",
    "PS-VB-2.0-RH",
    "PS-VB-ELASTIC-2",
    "PS-VB-HB-1,8",
    "PS-VB-SK-10",
    "PS-VB-SK-15",
    "PS-VB-SK-23",
    "PS-VB-SK-3",
    "PS-VB-SK-30",
    "PS-VB-SK-5",
    "PS-VB-Y-1.5",
    "PS-VB-Y-1-RH",
    "PS-VB-Y-2",
    "PS-VB-Y-2-RH",
    "PS-VB-Y-ELAST-1,5",
];
