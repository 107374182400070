import { Button, Checkbox, Modal, ModalContent, ModalFooter } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { ProductType } from "../../common/models/enums";
import { productListItem } from "../models/interfaces";
import { Product } from "../models/Product";
import projectStore from "../stores/ProjectStore";
import { PSAProductNames } from "../utilities/PSAProducten";
import { SliderProductNames } from "../utilities/SliderProducten";
import ArticleAmountInputField from "./ArticleAmountInputField";

function AddAdditionalProductModal() {
    const createItemList = (array: string[]): productListItem[] => {
        const productArray: productListItem[] = [];
        let planProductArray: Product[] = [];
        planProductArray = planProductArray.concat(projectStore!.getProductGroupsByType(ProductType.PSA).getProducts());
        planProductArray = planProductArray.concat(
            projectStore!.getProductGroupsByType(ProductType.SLIDER).getProducts(),
        );
        array.forEach((name) => {
            if (planProductArray.find((item) => item.getName() == name)) {
                productArray.push({
                    name: name,
                    amount: planProductArray.find((item) => item.getName() == name)!.getAmount(),
                });
            } else {
                productArray.push({ name: name, amount: 0 });
            }
        });

        return productArray;
    };

    const [filterTextPSA, setFilterTextPSA] = useState(() => "");
    const [filterTextSlider, setFilterTextSlider] = useState(() => "");

    const [PSAItems, setPSAItems] = useState<productListItem[]>(createItemList(PSAProductNames));
    const [SliderItems, setSliderItems] = useState<productListItem[]>(createItemList(SliderProductNames));

    const [PSAItemsOriginal, setPSAItemsOriginal] = useState<productListItem[]>(createItemList(PSAProductNames));
    const [SliderItemsOriginal, setSliderItemsOriginal] = useState<productListItem[]>(
        createItemList(SliderProductNames),
    );

    const handlePSAAmountChange = (name: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newItems = [...PSAItemsOriginal];
        newItems.find((item) => item.name == name)!.amount = parseInt(event.target.value);
        setPSAItemsOriginal(newItems);
    };

    const handleSliderAmountChange = (
        name: string,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newItems = [...SliderItems];
        newItems.find((item) => item.name == name)!.amount = parseInt(event.target.value);
        setSliderItemsOriginal(newItems);
    };

    const handleChangePSA = (e: { target: { value: string } }) => {
        setFilterTextPSA(e.target.value);
        const newItems = [...PSAItemsOriginal].filter((item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()),
        );
        setPSAItems(newItems);
    };

    const handleChangeSlider = (e: { target: { value: string } }) => {
        setFilterTextSlider(e.target.value);
        const newItems = [...SliderItemsOriginal].filter((item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()),
        );
        setSliderItems(newItems);
    };

    const handleDone = () => {
        addPSAToPlan();
        addSlidersToPlan();
        projectStore.setPsaProductenDialog(false);
    };
    function addPSAToPlan() {
        PSAItemsOriginal.forEach((product: productListItem) => {
            if (
                projectStore
                    .getProductGroupsByType(ProductType.PSA)
                    .getProducts()
                    .find((item) => item.getName() == product.name)
            ) {
                const prod = projectStore
                    .getProductGroupsByType(ProductType.PSA)
                    .getProducts()
                    .find((item) => item.getName() == product.name);
                prod?.setAmount(product.amount);
            } else {
                const newAddOnProduct = new Product(product.name, product.name, product.amount);
                newAddOnProduct.setType(ProductType.PSA);
                newAddOnProduct.setPartOf(projectStore.getDocumentKey());
                projectStore!.addProductToGroup(newAddOnProduct);
            }
        });
    }
    function addSlidersToPlan() {
        SliderItemsOriginal.forEach((product: productListItem) => {
            if (
                projectStore
                    .getProductGroupsByType(ProductType.SLIDER)
                    .getProducts()
                    .find((item) => item.getName() == product.name)
            ) {
                const prod = projectStore
                    .getProductGroupsByType(ProductType.SLIDER)
                    .getProducts()
                    .find((item) => item.getName() == product.name);
                prod?.setAmount(product.amount);
            } else {
                const newAddOnProduct = new Product(product.name, product.name, product.amount);
                newAddOnProduct.setType(ProductType.SLIDER);
                newAddOnProduct.setPartOf(projectStore.getDocumentKey());
                projectStore!.addProductToGroup(newAddOnProduct);
            }
        });
    }

    return (
        <Modal onCloseClick={() => projectStore.setPsaProductenDialog(false)} size="md" isOpen={true}>
            <ModalContent>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div
                        style={{
                            height: 450,
                            display: "flex",
                            flex: 2,
                            flexDirection: "column",
                        }}
                    >
                        <h1 style={{ marginBottom: 10 }}>Gleiter*</h1>
                        <Checkbox
                            onChange={() => projectStore.toggleAltSlider()}
                            labelId={"SliderAlt"}
                            name={"SliderAlt"}
                            small
                            text={"Alternativ"}
                            checked={projectStore.getAlternativeSlider()}
                        />
                        <input
                            type="text"
                            style={{ marginBottom: 15 }}
                            placeholder="Gleiter Liste filtern..."
                            value={filterTextSlider}
                            onChange={handleChangeSlider}
                        />
                        {SliderItems.map((item, index) => {
                            return (
                                <ArticleAmountInputField
                                    key={index}
                                    amount={0}
                                    productName={item.name}
                                    index={index}
                                    handleAmountChange={handleSliderAmountChange}
                                    item={item}
                                />
                            );
                        })}
                    </div>
                    <div
                        style={{
                            marginLeft: 15,
                            display: "flex",
                            flex: 2,
                            flexDirection: "column",
                        }}
                    >
                        <h1 style={{ marginBottom: 10 }}>PSA*</h1>
                        <Checkbox
                            onChange={() => projectStore.toggleAltPSA()}
                            labelId={"PSAAlt"}
                            name={"PSAAlt"}
                            small
                            text={"Alternativ"}
                            checked={projectStore.getAlternativePSA()}
                        />
                        <input
                            type="text"
                            style={{ marginBottom: 15 }}
                            placeholder="PSA Liste filtern..."
                            value={filterTextPSA}
                            onChange={handleChangePSA}
                        />

                        {PSAItems.length == PSAItemsOriginal.length
                            ? PSAItemsOriginal.map((item, index) => {
                                  return (
                                      <ArticleAmountInputField
                                          key={index}
                                          amount={0}
                                          productName={item.name}
                                          index={index}
                                          handleAmountChange={handlePSAAmountChange}
                                          item={item}
                                      />
                                  );
                              })
                            : PSAItems.map((item, index) => {
                                  return (
                                      <ArticleAmountInputField
                                          key={index}
                                          amount={0}
                                          productName={item.name}
                                          index={index}
                                          handleAmountChange={handlePSAAmountChange}
                                          item={item}
                                      />
                                  );
                              })}
                    </div>
                </div>
            </ModalContent>
            <ModalFooter>
                <div style={{ display: "flex", width: "100%" }}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: "left",
                            fontSize: "1.2rem",
                            fontWeight: "bolder",
                        }}
                    >
                        * Mindestens 2
                    </div>
                    <div
                        style={{
                            flex: 1,
                            textAlign: "right",
                        }}
                    >
                        <Button>
                            <button onClick={handleDone}>Speichern</button>
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}
export default observer(AddAdditionalProductModal);
