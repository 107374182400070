import { Matrix3x2, Point2 } from "@abs-safety/tsdtk";
import { DrawLine, DrawTypes, Point2D } from "../models/interfaces";
import { Draw } from "../models/lockBookDrawInterface";

const arcFlatness = 0.1;

export function* createDrawListGenerator(elements: Draw[]): Generator<{ lines: DrawLine[] }> {
    let prev = elements[elements.length - 1];
    let idx = 0;

    while (idx < elements.length) {
        const curr: Draw = elements[idx];

        switch (curr.type) {
            case DrawTypes.LINE: {
                // Simply copy over the line
                const lines = [{ ...curr }];
                yield { lines };
                break;
            }
            case DrawTypes.ARC: {
                // Create new lines from circle points
                const points = createCircularSegmentPoints(prev.to, curr.to, curr.radius!);
                const lines = points.map<DrawLine>((point) => ({
                    type: DrawTypes.LINE,
                    to: { x: point.x, y: point.y },
                    safe: curr.safe,
                }));

                yield { lines };
                break;
            }
        }

        prev = curr;
        idx++;
    }
}

const getCircularSegmentRadiusSweep = (p0: Point2, p1: Point2, sag: number): [number, number] => {
    const a = p1.sub(p0).length;
    const h = Math.abs(sag);
    const rad = (4 * h * h + a * a) / Math.abs(8 * h);
    const sweep = 4 * Math.atan(Math.abs(2 * h) / a);

    return [rad, sweep];
};

const getCircularSegmentCount = (rad: number, sweep: number, flatness: number): number => {
    const angle = 2 * Math.acos((rad - flatness) / rad);

    return Math.ceil(sweep / angle);
};

const getCircularSegmentMatrix = (p0: Point2, p1: Point2, sag: number, rad: number, sweep: number): Matrix3x2 => {
    const v1 = p1.sub(p0);
    const v2 = v1.unit.normal;
    const pc = p0.addMul(v1, 0.5).addMul(v2, -sag);

    // Create a matrix to transform points from the unit circle
    const tm = Matrix3x2.fromRotationAngle(-0.5 * sweep);
    tm.translatePre(-1, 0);
    tm.scalePre(sag < 0 ? rad : -rad, rad);
    tm.rotatePre(v2.y, v2.x);
    tm.translatePre(pc.x, pc.y);

    return tm;
};

const mapUnitPoint = (mat: Matrix3x2, a: number): Point2 => {
    const x = Math.cos(a);
    const y = Math.sin(a);
    const p = new Point2(x, y);
    return mat.mapPoint(p);
};
export const createCircularSegmentPoints = (from: Point2D, to: Point2D, sag: number): Point2D[] => {
    if (Math.abs(sag) < 0.001) {
        return [to];
    }

    const p0 = Point2.fromObject(from);
    const p1 = Point2.fromObject(to);

    const [rad, sweep] = getCircularSegmentRadiusSweep(p0, p1, sag);
    const count = getCircularSegmentCount(rad, sweep, arcFlatness);
    const mat = getCircularSegmentMatrix(p0, p1, sag, rad, sweep);

    const points: Point2D[] = [];

    for (let i = 1; i < count; i++) {
        const a = (i * sweep) / count;
        const pp = mapUnitPoint(mat, a);

        points.push(pp);
    }

    points.push(to);

    return points;
};
