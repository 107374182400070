import { makeAutoObservable } from "mobx";
import { ProductType } from "../../common/models/enums";
import { ExportComment } from "../../common/models/export-comment";
import projectStore from "../stores/ProjectStore";
import { findProductByIdAndProductName, sortProductGroup } from "../utilities/Functions";
import { PartialSum } from "./PartialSum";
import { Product } from "./Product";
import { ProductGroup } from "./ProductGroup";
import { RoofSection } from "./RoofSection";

export class RoofAccess {
    private _id: string;
    private _checked: boolean;
    private _name: string;
    private readonly _roofsection: RoofSection;
    private _classname: string;
    private readonly _productGroups: ProductGroup[];
    private readonly _comment: ExportComment;
    private readonly _partialSum: PartialSum;

    constructor(id: string, roofSection: RoofSection) {
        makeAutoObservable(this);
        this._id = id;
        this._checked = true;
        this._name = "";
        this._roofsection = roofSection;
        this._classname = "ROOFACCESS";
        this._productGroups = [];
        this._comment = new ExportComment(this.getId());
        Object.keys(ProductType).forEach((key: string) => {
            this._productGroups.push(new ProductGroup(key, roofSection));
        });
        this._partialSum = new PartialSum(this);
    }

    toggle(): void {
        this.setChecked(!this.getChecked());

        if (!this.getRoofSection().getChecked()) {
            this.getRoofSection().setChecked(true);
        }
        if (!this.getRoofSection().getPlan().getChecked()) {
            this.getRoofSection().getPlan().setChecked(true);
        }
    }

    getClassName(): string {
        return this._classname;
    }

    setClassName(className: string): void {
        this._classname = className;
    }

    getId(): string {
        return this._id;
    }

    setId(id: string): void {
        this._id = id;
    }

    getChecked(): boolean {
        return this._checked;
    }

    setChecked(checked: boolean): void {
        this._checked = checked;
    }

    getName(): string {
        return this._name;
    }

    setName(name: string): void {
        this._name = name;
    }

    getRoofSection(): RoofSection {
        return this._roofsection;
    }

    getItems() {
        let response: (ExportComment | Product | PartialSum)[] = [];
        this.addComment(response);
        this._productGroups.forEach((productGroup: ProductGroup) => {
            if (productGroup.getProducts().length > 0) {
                response.push(productGroup.getComment());
                response = response.concat(sortProductGroup(productGroup.getProducts(), productGroup.getType()));
            }
        });
        if (response.length > 0 && projectStore.getPartSum()) {
            response.push(this._partialSum);
        }

        return response;
    }

    getProductGroupsByType(type: ProductType): ProductGroup {
        return this._productGroups[Object.values(ProductType).indexOf(type)];
    }

    addProductToGroup(product: Product): void {
        const productGroup = this.getProductGroupsByType(product.getType());
        const productListItem = findProductByIdAndProductName(product, productGroup.getProducts());
        if (productListItem != null) {
            const addValue = product.getAmount();
            productListItem.setAmount(productListItem.getAmount() + addValue);
        } else {
            productGroup.addProduct(product);
        }
    }

    getProductsByProductGroup(productGroupName: string): Product[] {
        let products: Product[] = [];
        this._productGroups.forEach((productGroup: ProductGroup) => {
            if (productGroup.getType() == productGroupName) {
                products = products.concat(productGroup.getProducts());
            }
        });
        return products;
    }

    private addComment(items: (ExportComment | Product | PartialSum)[]) {
        if (projectStore.getComments()) {
            items.push(this._comment);
        }
    }

    setAllProductsToAlt() {
        this._productGroups.forEach((group: ProductGroup) => {
            group.getProducts().forEach((product: Product) => {
                product.toggleAlternative();
            });
        });
    }
}
