import { makeAutoObservable } from "mobx";
import { ProductType } from "../../common/models/enums";
import { ExportComment } from "../../common/models/export-comment";
import projectStore from "../stores/ProjectStore";
import { findProductByIdAndProductName, productTypeFormsGroup } from "../utilities/Functions";
import { ICheck } from "./IChecked";
import { IClassName } from "./IClassName";
import { PartialSum } from "./PartialSum";
import { PlanProducts } from "./PlanProducts";
import { Product } from "./Product";
import { ProductGroup } from "./ProductGroup";
import { RoofSection } from "./RoofSection";

export class Plan implements ICheck, IClassName {
    private _id: string;
    private _title: string;
    private _roofSections: RoofSection[];
    private _checked: boolean;
    private _products: PlanProducts;
    private _productAltChecked: boolean;
    private readonly _classname: string;
    private readonly _comment: ExportComment;
    private readonly _partialSum: PartialSum;
    private readonly _productGroups: ProductGroup[];

    toggle(): void {
        this._checked = !this._checked;
        this.getRoofSections().forEach((rf) => {
            rf.setChecked(this._checked);
            rf.getGuardSystems().forEach((gs) => {
                gs.setChecked(this._checked);
            });
            rf.getRailSystems().forEach((rs) => {
                rs.setChecked(this._checked);
            });
            rf.getRoofAccesses().forEach((ra) => {
                ra.setChecked(this._checked);
            });
            rf.getProductGroups().forEach((pg) => {
                pg.setChecked(this._checked);
            });
            rf.getSystems().forEach((s) => {
                s.setChecked(this._checked);
            });
        });
        this.getPlanProducts().setChecked(this._checked);
    }

    constructor(id: string, title: string, roofSections: RoofSection[]) {
        makeAutoObservable(this);
        this._id = id;
        this._title = title;
        this._roofSections = roofSections;
        this._checked = true;
        this._productAltChecked = false;
        this._roofSections = [];
        this._products = new PlanProducts(this);
        this._classname = "PLAN";
        this._comment = new ExportComment(title);
        this._partialSum = new PartialSum(this);
        this._productGroups = [];
        Object.keys(ProductType).forEach((key: string) => {
            this._productGroups.push(new ProductGroup(key));
        });
        this._partialSum = new PartialSum(this);
    }
    getClassName(): string {
        return this._classname;
    }

    getId(): string {
        return this._id;
    }

    setId(id: string): void {
        this._id = id;
    }

    getTitle(): string {
        return this._title;
    }

    setTitle(title: string): void {
        this._title = title;
    }

    getRoofSections(): RoofSection[] {
        return this._roofSections;
    }

    getComment(): ExportComment {
        return this._comment;
    }

    getPartialSum(): PartialSum {
        return this._partialSum;
    }

    getCheckedRoofSection(): RoofSection[] {
        return this.getRoofSections().filter((roofSection) => roofSection.getChecked());
    }

    setRoofSections(roofSections: RoofSection[]): void {
        this._roofSections = roofSections;
    }

    getChecked(): boolean {
        return this._checked;
    }

    setChecked(value: boolean): void {
        this._checked = value;
    }

    getProducAltChecked(): boolean {
        return this._productAltChecked;
    }

    setProducAltChecked(productAdditionChecked: boolean): void {
        this._productAltChecked = productAdditionChecked;
    }

    toggleAltProducts() {
        this._productAltChecked = !this._productAltChecked;
        this._productAltChecked == true && this.setAllProductsInPlanToAlt();
    }

    setAllProductsInPlanToAlt() {
        for (const group of this._productGroups) {
            for (const product of group.getProducts()) {
                product.toggleAlternative();
            }
        }
        for (const roofSection of this.getRoofSections()) {
            roofSection.setAllProductsToAlt();
        }
    }

    getPlanProducts(): PlanProducts {
        return this._products;
    }

    getPlanProductForOrder(): (Product | PartialSum)[] {
        let products: (Product | PartialSum)[] = [];
        if (this._products.getChecked()) {
            products = products.concat(this.getPlanProductsArray());
        }
        if (products.length > 0 && projectStore.getPartSum()) {
            products.push(this._partialSum);
        }
        this.getProductGroups().forEach((productGroup: ProductGroup) => {
            products = products.concat(productGroup.getProducts());
        });
        return products;
    }
    setPlanProducts(planProducts: PlanProducts): void {
        this._products = planProducts;
    }

    getPlanProductsArray(): Product[] {
        return this.getPlanProducts().getProducts();
    }

    getPlanProductIfChecked() {
        if (this.getPlanProducts().getChecked()) {
            return this.getPlanProductsArray();
        }
        return [];
    }
    getProductGroupsByType(type: ProductType): ProductGroup {
        return this._productGroups[Object.values(ProductType).indexOf(type)];
    }

    getProductGroups(): ProductGroup[] {
        return this._productGroups;
    }

    addProductToGroup(product: Product): void {
        if (productTypeFormsGroup(product.getType())) {
            const productGroup = this.getProductGroupsByType(product.getType());
            const productListItem = findProductByIdAndProductName(product, productGroup.getProducts());
            const addValue = product.getAmount();
            if (productListItem != null) {
                productListItem.setAmount(productListItem.getAmount() + addValue);
            } else {
                productGroup.addProduct(product);
            }
        }
    }
}
