import { action, computed, makeAutoObservable, observable } from "mobx";
import { ProductType } from "../../common/models/enums";
import { ExportComment } from "../../common/models/export-comment";
import { ICheck } from "./IChecked";
import { IClassName } from "./IClassName";
import { PartialSum } from "./PartialSum";
import { Product } from "./Product";
import { RoofSection } from "./RoofSection";

export class ProductGroup implements ICheck, IClassName {
    private readonly _type: string;
    private _checked: boolean;
    private _classname: string;
    private readonly _roofsection: RoofSection | undefined;
    @observable private _products: Product[];
    private readonly _comment: ExportComment;
    private readonly _partialSum: PartialSum;

    constructor(productGroup: string, roofSection?: RoofSection) {
        makeAutoObservable(this);
        this._type = productGroup;
        this._products = [];
        this._roofsection = roofSection;
        this._comment = new ExportComment(`${ProductType[productGroup as keyof typeof ProductType]}`);
        this._partialSum = new PartialSum(this);
        this._classname = "PRODUCTGROUP";
        this._checked = true;
    }

    getClassName(): string {
        return this._classname;
    }

    setClassName(className: string): void {
        this._classname = className;
    }

    toggle(): void {
        this.setChecked(!this.getChecked());

        if (this.getRoofSection()) {
            if (!this.getRoofSection()!.getChecked()) {
                this.getRoofSection()!.setChecked(true);
            }
            if (!this.getRoofSection()!.getPlan().getChecked()) {
                this.getRoofSection()!.getPlan().setChecked(true);
            }
        }
    }

    getRoofSection(): RoofSection | undefined {
        return this._roofsection;
    }

    getChecked(): boolean {
        return this._checked;
    }

    setChecked(checked: boolean): void {
        this._checked = checked;
    }

    getPartial(): PartialSum {
        return this._partialSum;
    }

    getComment(): ExportComment {
        return this._comment;
    }

    getType(): string {
        return this._type;
    }

    getProductType(): ProductType {
        return ProductType[this._type as keyof typeof ProductType];
    }

    @action setProducts(products: Product[]) {
        this._products = products;
    }

    @computed getProducts(): Product[] {
        return this._products;
    }

    addProduct(product: Product) {
        this._products.push(product);
    }
}
