import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { productType2Color } from "../../draw/utilities/EvaluateHtmlProperty";
import selectlineAPI from "../services/SelectlineApi";
import { ProductType } from "./enums";
import { ExportComment } from "./export-comment";
import { ExportItem } from "./export-item";

export class ExportArticle extends ExportItem {
    private id: string;
    private name: string;
    private amount: number;
    private type: ProductType;
    private alternative: boolean;
    private foreignKey?: string;
    protected unit?: string;

    constructor(id: string, name: string, amount: number, type: ProductType = ProductType.STANDALONE) {
        super();
        this.id = id;
        this.name = name;
        this.amount = amount;
        this.type = type;
        this.alternative = false;
    }

    getId(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    setName(name: string): void {
        this.name = name;
    }

    getAmount(): number {
        return this.amount;
    }

    setAmount(value: number): void {
        this.amount = value;
    }

    getType(): ProductType {
        return this.type;
    }
    setType(type: ProductType) {
        this.type = type;
    }
    getAlternative(): boolean {
        return this.alternative;
    }

    setAlternative(value: boolean) {
        this.alternative = value;
    }

    toggleAlternative() {
        this.alternative = !this.alternative;
    }

    saveProductInSelectline(documentKey: string): void {
        selectlineAPI.saveArticle(this, documentKey);
    }

    showItem(index: number): JSX.Element {
        return (
            <TableRow
                key={index}
                style={{
                    backgroundColor: productType2Color(this.getType()),
                }}
            >
                <TableCell style={{ fontSize: "0.8rem" }}>{this.getName()}</TableCell>
                {Array.from({ length: 2 }, (_, index) => (
                    <TableCell key={index}></TableCell>
                ))}
                <TableCell style={{ fontSize: "0.8rem" }} align="right">
                    {this.getAmount()}
                </TableCell>
            </TableRow>
        );
    }

    saveItem = (documentKey: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            selectlineAPI
                .saveArticle(this, documentKey)
                .then(() => {
                    resolve();
                })
                .catch((ex) => {
                    const comment = new ExportComment(`Uploadfehler - ${this.getName()}`);
                    comment.setAdditionalDescription(ex);

                    const articleInfosMap = {
                        Artikelnummer: this.getName(),
                        Menge: this.getAmount(),
                        Einheit: this.getUnit(),
                        "Alternative Position?": this.getAlternative(),
                        Fremdschlüssel: this.getForeignKey(),
                    };
                    comment.setMemo(
                        Object.entries(articleInfosMap)
                            .map(([key, value]) => `${key}:${value}`)
                            .join("\n"),
                    );

                    selectlineAPI
                        .saveComment(documentKey, comment)
                        .then(() => {
                            reject(`Artikel ${this.getName()} wurde als Kommentar angelegt`);
                        })
                        .catch(() => {
                            reject(`Artikel ${this.getName()} konnte nicht angelegt werden. ${ex}`);
                        });
                });
        });
    };

    getForeignKey(): string | undefined {
        return this.foreignKey;
    }

    setForeignKey(foreignKey: string) {
        this.foreignKey = foreignKey;
    }

    getUnit(): string | undefined {
        return this.unit;
    }

    setUnit(unit: string | undefined) {
        this.unit = unit;
    }
}
