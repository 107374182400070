import { ApiResponse, create } from "apisauce";
import { ExportArticle } from "../../common/models/export-article";
import { ExportComment } from "../../common/models/export-comment";
import { ExportItem } from "../../common/models/export-item";
import { ExportPartialSum } from "../../common/models/export-partial-sum";
import errorStore from "../../common/stores/ErrorStore";
import exportStore from "../../common/stores/ExportStore";
import { isUndefinedOrBlank } from "../../common/utilities/StringUtil";
import { TenderFixResponse } from "../models/tenderfixInterfaces";
import { TenderFixProject } from "../models/TenderFixProject";

export const getProject = (projectId: string) => {
    const baseUrl = `${process.env.REACT_APP_SLUI_PROXY_URL}tenderfix/`;
    const connector = create({
        baseURL: baseUrl,
    });

    errorStore.toggleLoading();
    connector
        .get<TenderFixResponse>(`ui/offer_data/${projectId}`)
        .then((response: ApiResponse<TenderFixResponse>) => {
            if (!response.ok) {
                errorStore.addErrorMessage("Beim Laden der Anfrage ist ein Fehler aufgetreten!");
            }
            return response.data;
        })
        .then((data: TenderFixResponse | undefined) => {
            if (data === undefined) {
                return;
            }

            const project = new TenderFixProject(projectId);

            const exportItems: ExportItem[] = [];

            data.data.forEach((lvPos) => {
                const comment = new ExportComment(`LV Pos. ${lvPos.rfq_uid}`);
                comment.setMemo(lvPos.entry_comments);
                exportItems.push(comment);

                lvPos.products.forEach((product) => {
                    if (!isUndefinedOrBlank(product.comments)) {
                        const comment = new ExportComment(
                            `Info zu LV Pos. ${lvPos.rfq_uid} - ${product.recommendation}`,
                        );
                        comment.setMemo(product.comments);
                        exportItems.push(comment);
                    }

                    const article = new ExportArticle(
                        product.recommendation,
                        product.recommendation,
                        +product.quantity.replace(",", "."),
                    );
                    article.setForeignKey(lvPos.rfq_uid);
                    article.setUnit(product.unit);
                    const isAlternative = product.alternative_item || product.optional_item;
                    article.setAlternative(isAlternative);
                    exportItems.push(article);
                });

                const partialSum = new ExportPartialSum(`LV Pos. ${lvPos.rfq_uid}`);
                exportItems.push(partialSum);
            });
            project.setExportItems(exportItems);
            exportStore.setProject(project);
        })
        .finally(() => {
            errorStore.toggleLoading();
        });
};
