import { makeAutoObservable, runInAction } from "mobx";
import { ProductType } from "../../common/models/enums";
import { ExportComment } from "../../common/models/export-comment";
import selectlineAPI from "../../common/services/SelectlineApi";
import errorStore from "../../common/stores/ErrorStore";
import exportStore from "../../common/stores/ExportStore";
import { SortProductsBy } from "../models/enums";
import { ICheck } from "../models/IChecked";
import { JSONProject } from "../models/lockBookDrawInterface";
import { OrderProject } from "../models/OrderProject";
import { PartialSum } from "../models/PartialSum";
import { Plan } from "../models/Plan";
import { Product } from "../models/Product";
import { ProductGroup } from "../models/ProductGroup";
import { LockBookDrawApi } from "../services/LockBookDrawApi";
import { groupAllProducts, sortProductGroup } from "../utilities/Functions";
import { JSONHandler } from "../utilities/JSONHandler";

class ProjectStore {
    private lockBookdrawApi: LockBookDrawApi;
    private _project: OrderProject;
    private _jsonHandler: JSONHandler | undefined;
    private _documentKey: string;
    private _partSum: boolean;
    private _comments: boolean;
    private _psaProductenDialog: boolean;
    private _addOnProducts: boolean;
    private _connections = 0;
    private _drawPrice: boolean;
    private _alternativePSA: boolean;
    private _alternativeSlider: boolean;
    private readonly NEEDEDCONNECTIONS = 2;
    private _lockbookDrawId = "";
    private _sortOn: SortProductsBy;
    private readonly _productGroups: ProductGroup[];

    constructor() {
        makeAutoObservable(this);
        this._partSum = false;
        this._comments = false;
        this._drawPrice = false;
        this._psaProductenDialog = false;
        this._addOnProducts = false;
        this._alternativePSA = false;
        this._alternativeSlider = false;
        this._documentKey = "";
        this.lockBookdrawApi = new LockBookDrawApi();
        this._project = new OrderProject("", "");
        this._sortOn = SortProductsBy.PLAN;
        this._productGroups = [];
        Object.keys(ProductType).forEach((key: string) => {
            this._productGroups.push(new ProductGroup(key));
        });
    }

    getResultColumnLength(): number {
        return 4;
    }

    getAddOnProducts(): boolean {
        return this._addOnProducts;
    }

    setAddOnProducts(value: boolean) {
        this._addOnProducts = value;
    }

    getPsaProductenDialog(): boolean {
        return this._psaProductenDialog;
    }

    setPsaProductenDialog(value: boolean) {
        this._psaProductenDialog = value;
    }

    getLockBookDrawId(): string {
        return this._lockbookDrawId;
    }

    setLockBookDrawId(value: string): void {
        this._lockbookDrawId = value;
    }

    getJsonHandler(): JSONHandler | undefined {
        return this._jsonHandler;
    }

    setJsonHandler(value: JSONHandler | undefined): void {
        this._jsonHandler = value;
    }

    getDocumentKey(): string {
        return this._documentKey;
    }

    setDocumentKey(value: string): void {
        this._documentKey = value;
    }

    getPartSum(): boolean {
        return this._partSum;
    }

    setPartSum(value: boolean): void {
        this._partSum = value;
    }

    getCheckedPlans(): Plan[] {
        return this._project.getCheckedObjects();
    }

    getProject(): OrderProject {
        return this._project;
    }

    setProject(value: OrderProject): void {
        this._project = value;
    }

    getDrawPrice(): boolean {
        return this._drawPrice;
    }

    setDrawPrice(value: boolean): void {
        this._drawPrice = value;
    }

    getComments(): boolean {
        return this._comments;
    }

    setComments(value: boolean): void {
        this._comments = value;
    }

    getSortOn(): SortProductsBy {
        return this._sortOn;
    }

    getCountConnections(): number {
        return this._connections;
    }

    setCountConnections(value: number): void {
        this._connections = value;
    }

    getNeededConnections(): number {
        return this.NEEDEDCONNECTIONS;
    }

    public setSortOn(sortOn: SortProductsBy): void {
        this._sortOn = sortOn;
    }

    public getLockBookDrawProject(projectId: string) {
        errorStore.toggleLoading();
        if (projectId.includes("https://draw.lock-book.com/projects/")) {
            projectId = projectId.split("/")[4];
        }
        this.lockBookdrawApi
            .getConnector()
            .get("projects/" + `${projectId}`)

            .then((response: any) => {
                if (response.problem) {
                    errorStore.addErrorMessage(
                        "Sie sind nicht eingeloggt oder Ihr Konto verfügt nicht über die erforderlichen Berechtigungen.",
                    );
                }
                return response.data.project;
            })
            .then((response: JSONProject) => {
                runInAction(() => {
                    this._jsonHandler = new JSONHandler(response);
                    this._project = this._jsonHandler!.setProject();
                });
            })
            .finally(() => {
                errorStore.toggleLoading();
            });
    }

    async saveOrderItemInSelectline(product: Product): Promise<void> {
        return new Promise((resolve) => {
            resolve(selectlineAPI.saveArticle(product, this._documentKey));
        });
    }

    public toggleItem(type: ICheck): void {
        type.toggle();
    }

    public togglePartSum(): void {
        this._partSum = !this._partSum;
    }

    public toggleComments(): void {
        this._comments = !this._comments;
    }

    public toggleDrawPrice(): void {
        this._drawPrice = !this._drawPrice;
    }

    getAlternativeSlider(): boolean {
        return this._alternativeSlider;
    }

    setAlternativeSlider(value: boolean) {
        this._alternativeSlider = value;
    }

    getAlternativePSA(): boolean {
        return this._alternativePSA;
    }

    setAlternativePSA(value: boolean) {
        this._alternativePSA = value;
    }

    toggleAltPSA() {
        this._alternativePSA = !this._alternativePSA;
    }

    toggleAltSlider() {
        this._alternativeSlider = !this._alternativeSlider;
    }
    async saveItems(): Promise<void> {
        try {
            if (projectStore.getDrawPrice()) {
                await selectlineAPI.addOrderConfig(projectStore.getDocumentKey());
            }
            await selectlineAPI.addProjectIdToDocument(projectStore.getDocumentKey(), projectStore.getLockBookDrawId());
        } catch (error: any) {
            errorStore.addErrorMessage(error);
            return;
        }

        const exportItems = this.getItems();
        exportStore.setExportProgress({ totalCount: exportItems.length, documentedCount: 0, lockedCount: 0 });

        for (const item of exportItems) {
            try {
                await item.saveItem(projectStore.getDocumentKey());
                exportStore.incrementDocumentedCount();
            } catch (error: any) {
                errorStore.addErrorMessage(error);
                exportStore.incrementLockedCount();
            }
        }
    }

    returnResponse(): JSX.Element[] {
        const response: JSX.Element[] = [];
        this.getItems().forEach((item: ExportComment | Product | PartialSum, index: number) => {
            response.push(item.showItem(index));
        });
        return response;
    }

    getItems() {
        let items: (ExportComment | Product | PartialSum)[] = [];
        const checkedObjects = this.getProject().getCheckedObjects();
        const sortOn = projectStore.getSortOn();
        const comments = projectStore.getComments();
        const partSum = projectStore.getPartSum();

        for (const plan of checkedObjects) {
            const roofSectionItems: (ExportComment | Product | PartialSum)[] = [];

            items.push(plan.getComment());

            if (sortOn === SortProductsBy.PLAN) {
                const checkedRoofSections = plan.getCheckedRoofSection();
                if (checkedRoofSections) {
                    for (const roofSection of checkedRoofSections) {
                        roofSectionItems.push(...roofSection.getItems());
                    }
                }
                items = this.getProductsGroupedByPlan(plan, roofSectionItems, items);
            } else {
                const checkedRoofSection = plan.getCheckedRoofSection();
                if (checkedRoofSection) {
                    items.push(...checkedRoofSection.flatMap((rs) => rs.getItems()));
                }
            }
        }

        if (this._addOnProducts) {
            items.push(...this.getAllProductsOutOfAllProductsGroup());
        }

        return items.filter((item) => {
            return (
                (item instanceof ExportComment && comments) ||
                (item instanceof PartialSum && partSum) ||
                item instanceof Product
            );
        });
    }

    checkPlanProductenAreAlternative(plan: Plan) {
        return plan.getProducAltChecked();
    }

    private getProductsGroupedByPlan(
        plan: Plan,
        roofSectionItems: (ExportComment | Product | PartialSum)[],
        items: (ExportComment | Product | PartialSum)[],
    ) {
        const groupedProducts: { [key: string]: Product[] } = {};

        roofSectionItems.forEach((item: ExportComment | Product | PartialSum) => {
            if (item instanceof Product) {
                const productType = Object.keys(ProductType)[Object.values(ProductType).indexOf(item.getType())];
                if (!groupedProducts[productType]) {
                    groupedProducts[productType] = [];
                }
                groupAllProducts(plan, item, groupedProducts[productType]);
            }
        });

        plan.getProductGroups().forEach((productGroup: ProductGroup) => {
            const productType = productGroup.getType();
            if (groupedProducts[productType] && groupedProducts[productType].length > 0) {
                items.push(productGroup.getComment());
                items = items.concat(sortProductGroup(groupedProducts[productType], productType));
            }
        });

        if (projectStore.getPartSum()) {
            items.push(plan.getPartialSum());
        }

        return items;
    }

    async saveOrder(): Promise<void> {
        exportStore.setIsExporting(true);
        await this.saveItems().then(() => {
            exportStore.setIsExporting(false);
        });
    }

    getProductGroupsByType(type: ProductType): ProductGroup {
        return this._productGroups[Object.values(ProductType).indexOf(type)];
    }

    addProductToGroup(product: Product): void {
        const productGroup = this.getProductGroupsByType(product.getType());
        const productIndex = productGroup
            .getProducts()
            .findIndex((p) => p.getId() === product.getId() && p.getName() === product.getName());
        if (productIndex !== -1) {
            const productListItem = productGroup.getProducts()[productIndex];
            const addValue = product.getAmount();
            productListItem.setAmount(productListItem.getAmount() + addValue);
        } else {
            productGroup.addProduct(product);
        }
    }

    getAllProductsOutOfAllProductsGroup(): (Product | ExportComment | PartialSum)[] {
        let products: (Product | ExportComment | PartialSum)[] = [];

        this._productGroups
            .filter((item) => item.getProducts().filter((item) => item.getAmount() > 0).length > 0)
            .forEach((productGroup: ProductGroup) => {
                products.push(productGroup.getComment());
                products = products.concat(productGroup.getProducts().filter((item) => item.getAmount() > 0));
                if (productGroup.getType() === ProductType.PSA) {
                    if (projectStore.getAlternativePSA()) {
                        productGroup.getProducts().forEach((prod: Product) => {
                            prod.setAlternative(true);
                        });
                    }
                } else if (productGroup.getType() === ProductType.SLIDER) {
                    if (projectStore.getAlternativeSlider()) {
                        productGroup.getProducts().forEach((prod: Product) => {
                            prod.setAlternative(true);
                        });
                    }
                }
                products.push(productGroup.getPartial());
            });
        return products;
    }

    public resetProject(): void {
        this._project = new OrderProject("", "");
        this._productGroups.length = 0;
    }
}
const projectStore = new ProjectStore();
export default projectStore;
