import { Checkbox, IconPlus } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React from "react";
import { Plan } from "../models/Plan";
import projectStore from "../stores/ProjectStore";
import { class2Margin } from "../utilities/EvaluateHtmlProperty";
function AddOnProductsListItem({ ...props }: { name: string; plan?: Plan }) {
    return (
        <div key={"props.key"} style={{ marginTop: 15 }}>
            <div
                id={props.name}
                style={{
                    marginLeft: class2Margin("PLAN"),
                    flex: 1,
                    flexDirection: "row",
                }}
            >
                <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
                    <Checkbox
                        onChange={() => projectStore.setAddOnProducts(!projectStore.getAddOnProducts())}
                        labelId={props.name}
                        name={props.name}
                        small
                        text={props.name}
                        checked={projectStore.getAddOnProducts()}
                    />
                    {projectStore.getAddOnProducts() && (
                        <div
                            onClick={() => {
                                projectStore.setPsaProductenDialog(true);
                            }}
                            style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}
                        >
                            <IconPlus height={12} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default observer(AddOnProductsListItem);
