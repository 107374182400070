import { Checkbox } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React from "react";
import { ExportItemGroup } from "../models/export-item-group";

type OptionListItemProps = {
    name: string;
    articleGroup: ExportItemGroup;
    checked: boolean;
};

const OptionListItem: React.FC<OptionListItemProps> = (props) => {
    const callback = React.useCallback(() => {
        props.articleGroup.toggleChecked();
    }, [props.articleGroup]);

    return (
        <div style={{ marginTop: 15 }}>
            <div id={props.name} style={{ flex: 1, flexDirection: "row" }}>
                <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
                    <Checkbox
                        onChange={callback}
                        labelId={props.name}
                        name={props.name}
                        small
                        text={props.name}
                        checked={props.checked}
                    />
                </div>
            </div>
        </div>
    );
};
export default observer(OptionListItem);
