import { ProductType } from "../../common/models/enums";
import { ExportComment } from "../../common/models/export-comment";
import { RailGuardOrder, SystemOrder } from "../models/enums";
import { PartialSum } from "../models/PartialSum";
import { Plan } from "../models/Plan";
import { Product } from "../models/Product";

export function sortProductByProductType(products: Product[]): Product[] {
    let sortedArticles: Product[] = [];
    Object.values(ProductType).forEach((value) => {
        sortedArticles = sortedArticles.concat(products.filter((p) => p.getType() === value));
    });
    return sortedArticles;
}

export function sortProductByProductName(products: Product[]): Product[] {
    return products.slice().sort(function (a, b) {
        if (a.getName() < b.getName()) {
            return -1;
        } else if (a.getName() > b.getName()) {
            return 1;
        } else {
            return 0;
        }
    });
}

export function sortProductGroup(systemAdditionProducts: Product[], type: string) {
    let products: Product[] = [];
    if (type === "ADDITION") {
        const unsortedArray: Product[] = systemAdditionProducts;
        Object.values(SystemOrder).forEach((systemOrder: string) => {
            products = products.concat(
                unsortedArray.filter((product: Product) => product.getName().startsWith(systemOrder)),
            );
        });
        unsortedArray.forEach((systemAdditionProduct: Product) => {
            const exists = Object.values(SystemOrder).some((systemOrder: string) => {
                return systemAdditionProduct.getName().startsWith(systemOrder);
            });
            if (!exists) {
                products.push(systemAdditionProduct);
            }
        });
    } else if (type === "RAILING") {
        const unsortedArray: Product[] = systemAdditionProducts;
        Object.values(RailGuardOrder).forEach((railGuardOrder: string) => {
            products = products.concat(
                unsortedArray.filter((product: Product) =>
                    product.getName().startsWith("SG2-DOME") || product.getName() === "SG2-FL-VB-ECK"
                        ? product.getName() === railGuardOrder
                        : product.getName().startsWith(railGuardOrder),
                ),
            );

            unsortedArray.filter((product: Product) => product.getName() === railGuardOrder);
            //products = sortProductGroupRailGuardSystem(products);
        });
    } else {
        products = systemAdditionProducts;
    }

    return groupProductsByProductGroup(products);
}

export function sortProductGroupRailGuardSystem(productList: Product[]) {
    const products: Product[] = [];

    productList.reduce((unsortedProductList: Product[], product: Product) => {
        Object.values(RailGuardOrder).forEach((railGuardOrder: string) => {
            if (product.getName() === railGuardOrder) {
                products.push(product);
            }
        });
        return unsortedProductList;
    }, []);
    return products;
}

export function groupProductsByProductGroup(products: Product[]): Product[] {
    let groupedProducts: Product[] = [];
    Object.values(ProductType).forEach((value) => {
        groupedProducts = groupedProducts.concat(products.filter((p) => p.getType() === value));
    });
    return groupedProducts;
}

export function groupProducts(products: Product[]): Product[] {
    const productList: Product[] = [];
    products.forEach((product: Product) => {
        const productListItem = findProductById(product.getId(), products);
        if (!productListItem) {
            products.push(product);
        } else {
            productListItem.setAmount(productListItem.getAmount() + product.getAmount());
        }
    });
    return productList;
}

export function findProductById(pid: string, products: Product[], idx: number = 0): null | Product {
    const item = products[idx];

    if (!item) return null;
    if (item.getId() === pid) return item;

    return findProductById(pid, products, idx + 1);
}

export function findProductByIdAndProductName(pid: Product, products: Product[], idx: number = 0): null | Product {
    if (products.length <= idx) {
        return null;
    }
    const item = products[idx];

    if (!item) return null;
    if (item.getId() === pid.getId() && pid.getName() === item.getName()) {
        return item;
    }

    return findProductByIdAndProductName(pid, products, idx + 1);
}

export function groupPlanProductsIntoProductGroups(items: (ExportComment | Product | PartialSum)[]): Product[] {
    let products: Product[] = items.filter(
        (item: ExportComment | Product | PartialSum) => item instanceof Product,
    ) as Product[];

    products = sortProductByProductType(products);

    return products;
}

export function groupAllProducts(plan: Plan, product: Product, products: Product[]) {
    if (productIsStackable(product)) {
        groupSameProductNamePieces(plan, products, product);
    } else {
        const newProduct = product.clone();
        newProduct.setPartOf(plan.getTitle());
        products.push(newProduct);
    }
}

export function groupSameProductNamePieces(plan: Plan, products: Product[], product: Product) {
    if (products.findIndex((p) => p.getId() === product.getId()) === -1) {
        const newProduct = product.clone();
        newProduct.setPartOf(plan.getTitle());
        products.push(newProduct);
    } else {
        products
            .find((p) => p.getId() === product.getId())!
            .setAmount(+products.find((p) => p.getId() === product.getId())!.getAmount() + +product.getAmount());
    }
}

export function productTypeFormsGroup(productType: ProductType) {
    return [
        ProductType.STANDALONE,
        ProductType.DRAINAGE,
        ProductType.INSULATION,
        ProductType.VAPOUR_BARRIER,
        ProductType.SEALING,
        ProductType.PRIMER,
        ProductType.BONDING,
        ProductType.PV,
    ].includes(productType);
}

export function productIsStackable(product: Product) {
    const unitIsM = product.getUnit()?.toLocaleUpperCase() === "M";
    const whitelist = ["PV-KABEL-4MM", "PV-KABEL-6MM"];
    return !unitIsM || whitelist.includes(product.getName());
}
