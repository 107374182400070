import { Input } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React from "react";
import { productListItem } from "../models/interfaces";

function ArticleAmountInputField(props: {
    item: productListItem;
    handleAmountChange: (productName: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    index: number;
    productName: string;
    amount: number;
}) {
    return (
        <div style={{ height: "auto", marginBottom: 8 }}>
            <Input
                min={0}
                onChange={(e) => props.handleAmountChange(props.productName, e)}
                label={props.productName}
                type="number"
                value={props.item.amount.toString()}
            />
        </div>
    );
}

export default observer(ArticleAmountInputField);
