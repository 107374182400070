import { makeAutoObservable } from "mobx";
import { IProject } from "../../common/interfaces/IProject";
import { ExportArticle } from "../../common/models/export-article";
import { ExportComment } from "../../common/models/export-comment";
import { ExportItem } from "../../common/models/export-item";
import { ExportItemGroup } from "../../common/models/export-item-group";
import { ExportPartialSum } from "../../common/models/export-partial-sum";

export class LadderProject implements IProject {
    private id: string;
    private constructionProject: string;
    private requestDate: string;
    private additionalInfos: Map<string, any>;
    private itemGroups: ExportItemGroup[];
    private comment: ExportComment;
    private _partSum: boolean;
    private _comments: boolean;

    constructor(id: string, defaultComment: ExportComment) {
        makeAutoObservable(this);
        this.id = id;
        this.constructionProject = "";
        this.requestDate = "";
        this.additionalInfos = new Map();
        this.itemGroups = [];
        this.comment = defaultComment;
        this._comments = false;
        this._partSum = false;
    }

    getPartSum(): boolean {
        return this._partSum;
    }

    setPartSum(value: boolean): void {
        this._partSum = value;
    }

    toggleComments(): void {
        this._comments = !this._comments;
    }

    togglePartSum(): void {
        this._partSum = !this._partSum;
    }

    getAdditionalInfos(): Map<string, any> {
        return this.additionalInfos;
    }

    setAdditionalInfos(additionalInfos: Map<string, any>): void {
        this.additionalInfos = additionalInfos;
    }

    getComments(): boolean {
        return this._comments;
    }

    setComments(value: boolean): void {
        this._comments = value;
    }

    getExportItems(): ExportItem[] {
        const showComments = this.getComments();
        const showPartSums = this.getPartSum();
        const items = [
            this.comment,
            ...this.itemGroups
                .filter((itemGroup) => itemGroup.getChecked())
                .flatMap((itemGroup) => itemGroup.getExportItems()),
        ];

        return items.filter((item) => {
            return (
                (item instanceof ExportComment && showComments) ||
                item instanceof ExportArticle ||
                (item instanceof ExportPartialSum && showPartSums)
            );
        });
    }

    getItemGroups(): ExportItemGroup[] {
        return this.itemGroups;
    }

    setItemGroups(itemGroups: ExportItemGroup[]): void {
        this.itemGroups = itemGroups;
    }

    setId(id: string): void {
        this.id = id;
    }
    getId() {
        return this.id;
    }

    setRequestDate(requestDate: string): void {
        this.requestDate = requestDate;
    }

    getRequestDate() {
        return this.requestDate;
    }

    setConstructionProject(constructionProject: string): void {
        this.constructionProject = constructionProject;
    }

    getConstructionProject() {
        return this.constructionProject;
    }

    setComment(comment: ExportComment): void {
        this.comment = comment;
    }

    getComment(): ExportComment {
        return this.comment;
    }
}
