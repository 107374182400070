import {ApisauceInstance, create} from "apisauce";

export class LockBookDrawApi {
    private _url!: string;
    private readonly _connector: ApisauceInstance;

    constructor() {
        this.setUrl(`${process.env.REACT_APP_LOCK_BOOK_DRAW_API_URL}`);
        this._connector = create({
            baseURL: `${this.getUrl()}`,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    getApiController(): ApisauceInstance {
        return this._connector;
    }
    getUrl(): string {
        return this._url;
    }

    setUrl(url: string) {
        this._url = url;
    }

    getConnector() {
        return this._connector;
    }
}
