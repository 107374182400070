import { SelectlineArticleRequestObject } from "../interfaces/selectlineInterfaces";
import { ExportArticle } from "../models/export-article";

const zeroPriceArticles: string[] = ["SG2-FL-VB-PRO"];

export default function tamperArticleFields(
    article: ExportArticle,
    requestDataObject: SelectlineArticleRequestObject,
): SelectlineArticleRequestObject {
    if (zeroPriceArticles.includes(article.getName())) {
        requestDataObject = { ...requestDataObject, UnitPrice: 0, TotalPrice: 0 };
    }
    return requestDataObject;
}
