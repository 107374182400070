export enum ImporterType {
    LADDER_CONFIG = "(ST)-",
    DRAW = "(DRAW)-",
    TENDERFIX = "TFX",
}

export enum ProductType {
    "STANDALONE" = "Einzelanschlagpunkt(e)",
    "EDGE" = "Anfangs-/Eckstütze(n)",
    "INTERMEDIATE" = "Zwischenstütze(n)",
    "ADDITION" = "Seilkomponente(n)",
    "CORNER" = "Eckstütze(n)",
    "ROOFACCESS" = "Dachzugang",
    "RAILING" = "Geländer",
    "SLIDER" = "Gleiter",
    "PSA" = "PSA",
    "DRAINAGE" = "Entwässerung",
    "INSULATION" = "Dämmung",
    "VAPOUR_BARRIER" = "Dampfsperre",
    "PV" = "Photovoltaik",
    "MOVEABLE_RAILING" = "verfahrbare(s) Geländer",
    "SEALING" = "Abdichtung",
    "PRIMER" = "Voranstrich",
    "BONDING" = "Dämmstoffkleber",
}
