import { makeAutoObservable } from "mobx";
import { ICheck } from "./IChecked";
import { IClassName } from "./IClassName";
import { IProducts } from "./IProducts";
import { Plan } from "./Plan";
import { Product } from "./Product";

export class PlanProducts implements ICheck, IProducts, IClassName {
    private _plan: Plan;
    private _products: Product[];
    private _checked: boolean;
    private readonly _classname: string;

    constructor(plan: Plan) {
        makeAutoObservable(this);
        this._products = [];
        this._plan = plan;
        this._checked = true;
        this._classname = "PLANPRODUCTS";
    }
    getClassName(): string {
        return this._classname;
    }

    toggle(): void {
        if (!this.getPlan().getChecked()) {
            this.getPlan().setChecked(true);
        }
        this._checked = !this._checked;
    }

    getPlan(): Plan {
        return this._plan;
    }

    setPlan(plan: Plan) {
        this._plan = plan;
    }

    getProducts(): Product[] {
        return this._products;
    }

    setProducts(products: Product[]) {
        this._products = products;
    }

    getChecked(): boolean {
        return this._checked;
    }

    setChecked(checked: boolean) {
        this._checked = checked;
    }
}
