import { ProductType } from "../../common/models/enums";
import { Draw } from "./lockBookDrawInterface";
import { Product as cJSONProduct } from "./Product";

export interface Flavoring<FlavorT> {
    _type?: FlavorT;
}

export type Flavor<T, FlavorT> = T & Flavoring<FlavorT>;

export type SurfaceEdgeAttachmentId = Flavor<string, "SurfaceEdgeAttachmentId">;

export interface Surface {
    base: Base;
    normal: Normal;
    xAxis: XAxis;
    material: string;
    type: number;
    transparency: number;
    draw: Draw[];
    selection: Selection;
    id: string;
    name: string;
}

export interface Base {
    z: number;
    x: number;
    y: number;
}

export interface Normal {
    x: number;
    y: number;
    z: number;
}

export interface XAxis {
    x: number;
    y: number;
    z: number;
}

export interface roofAccessDataSet {
    id: string;
    attachmentId: string;
    type: string;
    additions: Additions;
    isSelected: boolean;
    din: string;
    product: JSONProduct;
}

export interface JSONRoofAcces {
    type: string;
    data: roofAccessDataSet;
}

export interface FallProtectionDataSet {
    type: string;
    data: Data;
}

export interface WorldDataSet {
    type: string;
    data: Data;
}

export interface WorldDataVapourBarrierParent {
    id: string;
    components: {
        ROOF_SURFACE_REF: { value: string };
        BUILDING_PHYSICS_LAYER: {
            value: { type: "VAPOUR_BARRIER" };
        };
    };
}

export interface WorldDataSealingParent {
    id: string;
    components: {
        ROOF_SURFACE_REF: { value: string };
        BUILDING_PHYSICS_LAYER: {
            value: { type: "SEALING" };
        };
    };
}

export interface WorldDataPrimerParent {
    id: string;
    components: {
        ROOF_SURFACE_REF: { value: string };
        POSITION_FIXING_PRIMER: {
            type: "POSITION_FIXING_PRIMER";
            value: { selectedProduct: string };
        };
    };
}

export interface WorldDataBondingParent {
    id: string;
    components: {
        ROOF_SURFACE_REF: { value: string };
        POSITION_FIXING_BONDING: {
            type: "POSITION_FIXING_BONDING";
            value: { selectedProduct: string };
        };
    };
}

export interface WorldDataDrainageParent {
    id: string;
    components: {
        DRAINAGE?: {
            type: "DRAINAGE";
        };
        EDGE_DRAINAGE?: {
            type: "EDGE_DRAINAGE";
        };
        POSITION?: PositionType;
        ROOF_SURFACE_REF?: { value: string };
        SURFACE_EDGE_ATTACHMENT_REF?: { value: string };
    };
}

export interface WorldDataInsulationParent {
    id: string;
    components: {
        TAPERED_INSULATION?: {
            type: "TAPERED_INSULATION";
        };
        FLAT_INSULATION?: {
            type: "FLAT_INSULATION";
        };
        POSITION_2D: Position2dType;
        ROOF_SURFACE_REF: { value: string };
    };
}

export interface WorldDataPvParent {
    id: string;
    components: {
        PV_SUBSTRUCTION?: {
            type: "PV_SUBSTRUCTION";
            value: {
                centerPosition: {
                    x: number;
                    y: number;
                };
            };
        };
        PV_UNDERCONSTRUCTION?: {
            type: "PV_UNDERCONSTRUCTION";
        };
        PV_OTHER_COMPONENT?: {
            type: "PV_OTHER_COMPONENT";
        };
        PV_INVERTER?: {
            type: "PV_INVERTER";
        };
        PV_ROOF_SETTINGS?: {
            type: "PV_ROOF_SETTINGS";
        };
        PV_FIELD?: {
            type: "PV_FIELD";
        };
        SURFACE_PARENT_REF: { value: string };
    };
}

export interface WorldDataMoveableRailingParent {
    id: string;
    components: {
        MOVEABLE_RAILING: {
            type: "MOVEABLE_RAILING";
        };
        NAME: { value: string };
    };
}

export interface AttachementPointComponent {
    id: string;
    components: {
        ATTACHMENT_POINT_COMPONENT: {
            type: "ATTACHMENT_POINT_COMPONENT";
            value: {
                joints: {
                    topLeft?: MoveableRailingJoint;
                    topRight?: MoveableRailingJoint;
                    bottomLeft?: MoveableRailingJoint;
                    bottomRight?: MoveableRailingJoint;
                };
            };
        };
        SURFACE_PARENT_REF: { value: string };
    };
}

export interface MoveableRailingJoint {
    occupierId: string;
    type: "moveableRailing";
}

export interface WorldDataSystem {
    name: string;
    surfaceRef?: string;
    products: WorldDataProduct[];
}

export interface PositionType {
    type: "POSITION";
    value: {
        x: number;
        y: number;
        z: number;
    };
}

export interface Position2dType {
    type: "POSITION_2D";
    value: {
        x: number;
        y: number;
        z: number;
    };
}

export interface WorldDataProduct {
    id: string;
    components: {
        PRODUCT: {
            type: "PRODUCT";
            value: string;
        };
        AMOUNT: {
            type: "AMOUNT";
            value: {
                dimension: [number];
                unit: "pcs" | "m2" | "m3";
            };
        };
        PARENT_REF: {
            type: "PARENT_REF";
            value: string;
        };
    };
}

export interface WorldDataProductWithSurfaceRef extends WorldDataProduct {
    surfaceRef: string;
}

export interface WorldDataProductWithPosition extends WorldDataProductWithSurfaceRef {
    position: Position;
}

export interface Data {
    virtualDistance: number;
    anchoragePoints: AnchoragePoint[];
    edgeSystems: EdgeSystem[];
    id: string;
}

export interface AnchoragePoint {
    position: Position;
    name: string;
    id: string;
    orientation: Orientation;
    additions: Additions;
    additions_v2: AdditionsV2;
    isEnforced?: boolean;
    product: cJSONProduct;
    isVirtual?: boolean;
}

export interface Position {
    x: number;
    y: number;
    z: number;
}

export interface Orientation {
    type: number;
    z: Z;
    y: Y;
}

export interface Z {
    x: number;
    y: number;
    z: number;
}

export interface Y {
    x: number;
    y: number;
    z: number;
}

export interface Additions {
    generatedAdditions: GeneratedAddition[];
    userAdditions: UserAddition[];
    deletedGeneratedAdditions: string[];
}

export interface UserAddition {
    id: string;
    product: JSONProduct;
}
export interface GeneratedAddition {
    id: string;
    product: JSONProduct;
}

export interface JSONProduct {
    showItem(): void;

    id: string;
    amount: Amount;
    type: ProductType | undefined;
}

export interface Amount {
    dimension: number[];
    unit: string;
    condensable: boolean;
}

export interface EdgeSystem {
    additions: Addition[];
    anchoragePointRefs: number[];
    edges: Edge[];
    name: string;
    thickness: number;
    type: string;
    color: Color;
    id: string;
    additions_v2: AdditionsV2;
}

export interface Edge {
    type: number;
    id: string;
    virtualPointIndices: number[];
    gates?: Gate[];
}

export interface Gate {
    id: string;
    position: Position;
    additions_v2: AdditionsV2;
}

export interface Color {
    h: number;
    s: number;
    l: number;
}

export interface AdditionsV2 {
    userAdditions: UserAddition[];
    generatedAdditions: GeneratedAddition[];
    deletedGeneratedAdditions: string[];
}

export interface GeneratedAddition2 {
    additions: any;
    id: string;
    position: number;
    product: cJSONProduct;
    isEnforced: boolean;
}

export interface AdditionalDimensions {
    length: number[];
}

export interface RoofDataSet {
    data: Data;
    type: string;
}

export interface Data {
    id: string;
    surfaces: Surface[];
}

export interface Base {
    z: number;
    x: number;
    y: number;
}

export interface Normal {
    x: number;
    y: number;
    z: number;
}

export interface XAxis {
    x: number;
    y: number;
    z: number;
}

export interface Selection {
    vertexIndices?: number[];
}

export interface JSONProduct {
    id: string;
    amount: Amount;
}

export interface Amount {
    unit: string;
    dimension: number[];
    additionalDimensions: AdditionalDimensions;
}

export interface AdditionalDimensions {
    length: number[];
}

export interface Position {
    x: number;
    y: number;
    z: number;
}

export interface Orientation {
    type: number;
    z: Z;
    y: Y;
}

export interface Z {
    x: number;
    y: number;
    z: number;
}

export interface Y {
    x: number;
    y: number;
    z: number;
}

export interface Additions {
    generatedAdditions: GeneratedAddition[];
    userAdditions: UserAddition[];
    deletedGeneratedAdditions: string[];
}

export interface Amount {
    dimension: number[];
    unit: string;
    condensable: boolean;
}

export interface Amount {
    dimension: number[];
    unit: string;
    condensable: boolean;
}

export interface RoofEdge {
    type: number;
    to: To;
    edgeAttachments: EdgeAttachment[];
}

export interface To {
    x: number;
    y: number;
}

export interface EdgeAttachment {
    id: string;
    position: number;
}

export interface RailingDataSet {
    type: string;
    data: RailingData;
}

export interface RailingData {
    guardRails: GuardRail[];
    id: string;
}

export interface Location2 {
    origin: Origin;
    coordinateSystem: CoordinateSystem;
}

export interface Origin {
    x: number;
    y: number;
    z: number;
}

export interface CoordinateSystem {
    type: number;
    y: Y;
    z: Z;
}

export interface GuardRail {
    id: string;
    type: string;
    corners: AnchoragePoint[];
    edges: Edge[];
    name: string;
    additions_v2: AdditionsV2;
    additions: Addition[];
    hasToeboard?: boolean;
    location?: Location2;
}

export interface Addition {
    position: number;
    product: Product;
}

export interface Corner {
    position: Position;
    id: string;
    additions_v2: AdditionsV2;
}

export interface Product {
    id: string;
    amount: Amount;
}

export interface Position {
    x: number;
    y: number;
    z: number;
}

export interface GeneratedAddition {
    id: string;
    product: JSONProduct;
}

export interface Amount {
    dimension: number[];
    unit: string;
    condensable: boolean;
}

export interface Edge {
    id: string;
    gates?: Gate[];
    posts: Post[];
}

export interface Post {
    isGenerated: boolean;
    id: string;
    position: Position;
    additions_v2: AdditionsV2;
}

export interface Point2D<T = number> {
    x: T;
    y: T;
}

export interface SurfaceEdgeAttachment {
    id: SurfaceEdgeAttachmentId;
    position: number;
}

export enum DrawTypes {
    LINE = 0,
    ARC = 1,
    // CURVE,
}

interface DrawElementBase {
    to: Point2D;
    edgeAttachments?: SurfaceEdgeAttachment[];
    safe?: boolean;
}

export interface DrawLine extends DrawElementBase {
    type: DrawTypes.LINE;
}

export interface productListItem {
    name: string;
    amount: number;
}
