import { create } from "apisauce";
import { ImporterType } from "../../common/models/enums";
import { ExportArticle } from "../../common/models/export-article";
import { ExportComment } from "../../common/models/export-comment";
import { ExportItemGroup } from "../../common/models/export-item-group";
import { ExportPartialSum } from "../../common/models/export-partial-sum";
import errorStore from "../../common/stores/ErrorStore";
import exportStore from "../../common/stores/ExportStore";
import { Inquiry } from "../models/ladderImportTypes";
import { LadderProject } from "../models/LadderProject";

export const getProject = (projectId: string) => {
    const baseUrl = `${process.env.REACT_APP_SLUI_PROXY_URL}ladder-backend/`;
    const connector = create({
        baseURL: baseUrl,
    });

    const cleanProjectId = projectId.startsWith(ImporterType.LADDER_CONFIG)
        ? projectId.substring(ImporterType.LADDER_CONFIG.length)
        : projectId;
    errorStore.toggleLoading();
    connector
        .get(`api/inquiry-export/${cleanProjectId}`)
        .then((response: any) => {
            if (response.problem) {
                errorStore.addErrorMessage("Beim Laden der Anfrage ist ein Fehler aufgetreten!");
            }
            return response.data;
        })
        .then((inquiry: Inquiry) => {
            const comment = new ExportComment("Anfrage");
            const defaultComment =
                `Ihre Anfrage per Steigtechnik-Konfigurator vom ${new Date(inquiry.createdAt).toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric" })}\n\n` +
                `Hinweis: Dieses Angebot bezieht sich auf die im Steigtechnik-Konfigurator eingegebenen Informationen! Wir bitten Sie daher vor Bestellung Ausführbarkeit und Mengen zu überprüfen!`;
            comment.setMemo(defaultComment);

            const project = new LadderProject(projectId, comment);
            project.setConstructionProject(inquiry.constructionProject);

            // build additional infos
            const additionalInfos = new Map();
            const client = new Map();
            additionalInfos.set("Kundendaten", client);

            client.set("Firmenname", inquiry.customer.companyName);
            client.set("Straße", inquiry.customer.street);
            client.set("PLZ", inquiry.customer.postalCode);
            client.set("Ort", inquiry.customer.city);
            client.set("Ansprechpartner", inquiry.customer.contactPerson);
            client.set("E-Mail", inquiry.customer.email);
            client.set("Telefon", inquiry.customer.phoneNumber);

            project.setAdditionalInfos(additionalInfos);

            const itemGroups: ExportItemGroup[] = [];

            inquiry.configurationArticles.forEach((configuration) => {
                const itemGroup = new ExportItemGroup(configuration.assemblyName);

                configuration.category;

                configuration.items.forEach((item) => {
                    let exportItem: ExportArticle | ExportComment | ExportPartialSum;
                    switch (item.type) {
                        case "ARTICLE":
                            exportItem = new ExportArticle(item.id.toString(), item.name, item.quantity);
                            exportItem.setName(item.articleNo);
                            exportItem.setAmount(item.quantity);
                            break;
                        case "COMMENT":
                            exportItem = new ExportComment(item.name);
                            exportItem.setMemo(item.comment);
                            break;
                        case "PARTIAL_SUM":
                            exportItem = new ExportPartialSum(item.name);
                            break;
                        default:
                            throw new Error(`Unexpected item type.`);
                    }
                    itemGroup.addItem(exportItem);
                });
                itemGroups.push(itemGroup);
            });

            project.setItemGroups(itemGroups);
            exportStore.setProject(project);
        })
        .finally(() => {
            errorStore.toggleLoading();
        });
};
