import { Button, IconDownload } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AttributeComponent from "../../common/components/AttributeComponent";
import SubOptionItem from "../../common/components/SubOptionItem";
import errorStore from "../../common/stores/ErrorStore";
import exportStore from "../../common/stores/ExportStore";
import projectStore from "../stores/ProjectStore";

function DrawSection() {
    const [projectId, setProjectId] = useState("");
    const params = useParams();
    useEffect(() => {
        if (params.projectId) {
            setProjectId(params.projectId);
            projectStore.setLockBookDrawId(params.projectId);
        }
        if (params.documentKey) {
            exportStore.setDocumentKey(params.documentKey);
            projectStore.setDocumentKey(params.documentKey);
        }
    }, [params.projectId, params.documentKey]);

    return (
        <div>
            <h3 className="headerTitleBox">Draw Projekt</h3>
            <AttributeComponent onChange={setProjectId} label={"Draw-URL"} value={projectId} />
            <div className="headerTitleBox">
                <Button className="button">
                    <button
                        onClick={() => {
                            projectStore.getLockBookDrawProject(projectId);
                            projectStore.setLockBookDrawId(projectId);
                        }}
                        disabled={errorStore.getLoading() || exportStore.getIsExporting()}
                    >
                        Projekt laden
                        <IconDownload height={15} color="#FFFFFF" />
                    </button>
                </Button>
            </div>
            <AttributeComponent readonly={true} label={"Angebotsnummer"} value={exportStore.getDocumentKey()} />
            <AttributeComponent
                readonly={true}
                label={"Projektnummer"}
                value={projectStore.getProject().getShortId()}
            />
            <AttributeComponent
                readonly={true}
                label={"Projektname"}
                value={projectStore.getProject().getDescription()}
            />

            <SubOptionItem
                checked={projectStore.getDrawPrice()}
                name={"Gesamtdach-Angebot"}
                toolTipText={
                    "Bei einem Gesamtdach-Angebot wird in SelectLine die Preisgruppe auf 6 (Draw) und Belegrabatt und Rabattgruppe auf 0 gesetzt."
                }
                onClickFunc={() => {
                    projectStore.toggleDrawPrice();
                }}
            />
        </div>
    );
}
export default observer(DrawSection);
