import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { ItableRowItem } from "../../common/interfaces/ITableRowItem";
import selectlineAPI from "../../common/services/SelectlineApi";
import projectStore from "../stores/ProjectStore";
import { GuardSystem } from "./GuardSystem";
import { Plan } from "./Plan";
import { ProductGroup } from "./ProductGroup";
import { RailSystem } from "./RailSystem";
import { RoofAccess } from "./RoofAccess";
import { RoofSection } from "./RoofSection";
import { System } from "./System";

export class PartialSum implements ItableRowItem {
    private _parent:
        | RoofSection
        | GuardSystem
        | Plan
        | ProductGroup
        | null
        | RailSystem
        | RoofAccess
        | System
        | undefined;

    constructor(
        parent: RoofSection | GuardSystem | ProductGroup | Plan | RailSystem | RoofAccess | System | undefined,
    ) {
        this._parent = parent;
    }

    getParent(): RoofSection | GuardSystem | Plan | ProductGroup | null | RailSystem | RoofAccess | System | undefined {
        return this._parent;
    }

    showItem() {
        return (
            <TableRow key={Math.random() + "partial"}>
                <TableCell style={{ paddingTop: "8px", paddingBottom: "8px", fontSize: "24px" }}>&sum;</TableCell>
                {Array.from({ length: 2 }, (_, index) => (
                    <TableCell key={index}></TableCell>
                ))}
                <TableCell align="left" style={{ fontWeight: 900, fontSize: "0.8rem" }}>
                    Teilsumme{" "}
                    {this._parent instanceof ProductGroup
                        ? this._parent.getProductType()
                        : this._parent instanceof GuardSystem ||
                            this._parent instanceof System ||
                            this._parent instanceof RailSystem
                          ? this._parent.getName()
                          : this._parent instanceof RoofSection
                            ? "Dachfläche"
                            : this._parent instanceof Plan
                              ? "Plan"
                              : ""}
                </TableCell>
            </TableRow>
        );
    }

    saveItem(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            selectlineAPI
                .savePartialSum(projectStore.getDocumentKey())
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }
}
