import { Checkbox, IconInfo } from "@abs-safety/lock-book-web-ui";
import Tooltip from "@mui/material/Tooltip";
import useId from "@mui/utils/useId";
import { observer } from "mobx-react";
import React from "react";

function SubOptionItem(props: { name: string; checked: boolean; onClickFunc: () => void; toolTipText?: string }) {
    const generateId = useId();
    return (
        <div style={{ marginTop: 10, borderBottom: "1px solid rgb(0,0,0)" }}>
            <div id={generateId} style={{ marginLeft: 10, display: "flex", flexDirection: "row" }}>
                <Checkbox
                    onChange={() => props.onClickFunc()}
                    className={props.name}
                    name={props.name}
                    small
                    text={props.name}
                    checked={props.checked}
                />
                {props.toolTipText && (
                    <Tooltip disableFocusListener title={props.toolTipText}>
                        <div style={{ marginRight: 10, marginLeft: "auto" }}>
                            <IconInfo circle />
                        </div>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}

export default observer(SubOptionItem);
