import { makeAutoObservable } from "mobx";
import { ExportItem } from "./export-item";

export class ExportItemGroup {
    private name: string;
    private checked: boolean;
    private items: ExportItem[];

    constructor(name: string) {
        makeAutoObservable(this);
        this.name = name;
        this.items = [];
        this.checked = true;
    }

    getName(): string {
        return this.name;
    }

    getChecked(): boolean {
        return this.checked;
    }

    setChecked(value: boolean): void {
        this.checked = value;
    }

    toggleChecked(): void {
        this.setChecked(!this.checked);
    }

    addItem(item: ExportItem): void {
        this.items.push(item);
    }

    getExportItems(): ExportItem[] {
        return this.items;
    }
}
