import { Button, Modal, ModalContent, ModalFooter, ModalHeader } from "@abs-safety/lock-book-web-ui";
import { TextareaAutosize } from "@mui/material";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { ExportComment } from "../../models/export-comment";

function TextFieldModal(props: { onClickFunc: () => void; comment: ExportComment | null }) {
    const [text, setText] = useState(props.comment?.getMemo());
    return (
        <Modal onCloseClick={function noRefCheck() {}} size="sm" isOpen={true}>
            <ModalHeader title={props.comment?.getName()} />
            <ModalContent>
                <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    style={{ width: "100%", height: "100%" }}
                />
            </ModalContent>
            <ModalFooter>
                <Button>
                    <button
                        onClick={() => {
                            // @ts-ignore
                            props.comment?.setMemo(text.trim());
                            props.onClickFunc();
                        }}
                    >
                        OK
                    </button>
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default observer(TextFieldModal);
