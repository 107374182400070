export enum ItemType {
    "PLAN" = "PLAN",
    "PLANPRODUCTS" = "PLANPRODUCTS",
    "ROOFSECTION" = "ROOFSECTION",
    "ROOFSECTIONPRODUCTS" = "ROOFSECTIONPRODUCTS",
    "ADDITION" = "ADDITION",
    "GUARDSYSTEM" = "GUARDSYSTEM",
    "PRODUCT" = "PRODUCT",
}

export enum ProductTypeGroupComment {
    "STANDALONE" = "Einzelanschlagpunkt(e)",
    "EDGE" = "Anfangs-/Eckstütze(n)",
    "INTERMEDIATE" = "Zwischenstütze(n)",
    "ADDITION" = "Seilkomponente(n)",
    "CORNER" = "Eckstütze(n)",
    "ROOFACCESS" = "Dachzugang",
}

export enum SystemOrder {
    "FIRST" = "SY-1000",
    "SECOND" = "SY-SEIL",
    "THIRD" = "SY-1007",
    "FOURTH" = "SY-FL",
    "FIFTH" = "SY-TI",
    "SIXTH" = "SY-1031",
    "SEVENTH" = "SY-SC",
    "EIGHT" = "SY-1009",
}

export enum RailGuardOrder {
    "FIRST" = "SG2-GUARD",
    "SECOND" = "SG2-ENDBUEGEL",
    "THIRD" = "SG2-ECKE-VAR",
    "FOURTH" = "SG2-ECKE-FIX",
    "FIFTH" = "SG2-GATE",
    "SIXTH" = "SG2-WAND",
    "SEVENTH" = "SG2-FL-2,5",
    "EIGHT" = "SG2-FL-VB-ECK",
    "NINTH" = "SG2-ROHR-2500",
    "TENT" = "SG2-VB",
    "ELEVENTH" = "SG2-PFOST",
    "TWELFTH" = "SG2-FL-VB",
    "THIRTEENTH" = "SG2-FL-ANSCHL-",
    "FOURTEENTH" = "SG2-DOME-2,5-G-B",
    "FIFTEENT" = "SG2-DOME-2,5-G",
    "SIXTEENTH" = "SG2-DOME-2,5-B",
    "SEVENTEENTH" = "SG2-DOME-2,5-F",
}

export enum RoofSectionType {
    CABLE = "cable",
    TRAVERSABLE_CABLE = "traversableCable",
    TEMPORARY_CABLE = "single",
    NO_CABLE = "noCable",
}

export enum SortProductsBy {
    "PLAN" = "PLAN",
    "ROOFSECTION" = "ROOFSECTION",
    "GUARDSYSTEM" = "GUARDSYSTEM",
}

export const ADDONTYPE: string[] = ["Gleiter", "PSA"];
