import { Checkbox } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React from "react";
import { ICheck } from "../models/IChecked";
import { IClassName } from "../models/IClassName";
import { Plan } from "../models/Plan";
import { RoofSection } from "../models/RoofSection";
import { default as projectId } from "../stores/ProjectStore";
import { class2Margin } from "../utilities/EvaluateHtmlProperty";
function DrawOptionListItem({
    item,
    id,
    ...props
}: {
    key: string;
    id: string;
    name: string;
    item: ICheck & IClassName;
    checked: boolean;
    plan: Plan;
    roofSection?: RoofSection;
}) {
    const callback = React.useCallback(() => {
        projectId.toggleItem(item);
    }, [item]);

    return (
        <div key={props.key} style={{ marginTop: 15 }}>
            <div
                id={props.name}
                style={{ marginLeft: class2Margin(item.getClassName().toUpperCase()), flex: 1, flexDirection: "row" }}
            >
                <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
                    <Checkbox
                        onChange={callback}
                        labelId={props.name}
                        name={props.name}
                        small
                        text={props.name}
                        checked={props.checked}
                    />
                    {item instanceof Plan && (
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Checkbox
                                onChange={() => {
                                    item.toggleAltProducts();
                                }}
                                labelId={item.getTitle()}
                                name={props.name}
                                small
                                text={"Alt."}
                                checked={item.getProducAltChecked()}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default observer(DrawOptionListItem);
