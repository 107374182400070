import { TableCell, TableRow, Tooltip } from "@mui/material";
import React from "react";
import selectlineAPI from "../services/SelectlineApi";
import commentDialogStore from "../stores/CommentDialogStore";
import { ExportItem } from "./export-item";

export class ExportComment extends ExportItem {
    private name: string;
    private additionalDescription: string;
    private memo: string;

    constructor(name: string) {
        super();
        this.name = name;
        this.additionalDescription = "";
        this.memo = "";
    }

    public getName() {
        return this.name;
    }

    setName(name: string) {
        this.name = name;
    }

    public getAdditionalDescription() {
        return this.additionalDescription;
    }

    setAdditionalDescription(additionalDescription: string) {
        this.additionalDescription = additionalDescription;
    }

    getMemo(): string {
        return this.memo;
    }

    setMemo(text: string) {
        this.memo = text;
    }

    onClick() {
        commentDialogStore.setShowDialog(true);
        commentDialogStore.setComment(this);
    }

    showItem(index: number) {
        return (
            <TableRow key={index.toString()}>
                <TableCell align="left" style={{ fontWeight: 900, fontSize: "0.8rem" }}>
                    {this.getName()}
                </TableCell>
                {Array.from({ length: 2 }, (_, index) => (
                    <TableCell key={index}></TableCell>
                ))}
                <TableCell onClick={() => this.onClick()} align="right">
                    <Tooltip className={"comment"} disableFocusListener title={this.getMemo()}>
                        <div style={{}}>Kommentar</div>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }

    saveItem = (documentKey: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            selectlineAPI
                .saveComment(documentKey, this)
                .then(() => {
                    resolve();
                })
                .catch((ex) => {
                    reject(ex);
                });
        });
    };
}
