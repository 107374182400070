import { makeAutoObservable } from "mobx";
import { ExportComment } from "../models/export-comment";

class CommentDialogStore {
    private _showDialog: boolean;
    private _comment: ExportComment | null;

    constructor() {
        makeAutoObservable(this);
        this._showDialog = false;
        this._comment = null;
    }

    setShowDialog(textDialog: boolean): void {
        this._showDialog = textDialog;
    }

    setComment(comment: ExportComment): void {
        this._comment = comment;
    }

    getComment(): ExportComment | null {
        return this._comment;
    }

    getShowDialog(): boolean {
        return this._showDialog;
    }

    toggleShowDialog(): void {
        this._showDialog = !this._showDialog;
    }
}

const commentDialogStore = new CommentDialogStore();
export default commentDialogStore;
