import { makeAutoObservable } from "mobx";
import { IProject } from "../../common/interfaces/IProject";
import { ExportItem } from "../../common/models/export-item";

export class TenderFixProject implements IProject {
    private id: string;
    private exportItems: ExportItem[];
    constructor(id: string) {
        makeAutoObservable(this);
        this.id = id;
        this.exportItems = [];
    }
    getExportItems(): ExportItem[] {
        return this.exportItems;
    }
    setExportItems(exportItems: ExportItem[]) {
        this.exportItems = exportItems;
    }
    setId(id: string): void {
        this.id = id;
    }
    getId() {
        return this.id;
    }
}
