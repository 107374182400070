import { makeAutoObservable } from "mobx";

class ErrorStore {
    private _errorMessages: string[];
    private _loading: boolean;

    constructor() {
        makeAutoObservable(this);
        this._errorMessages = [];
        this._loading = false;
    }

    getErrorMessages(): string[] {
        return this._errorMessages;
    }

    addErrorMessage(message: string): void {
        this._errorMessages.push(message);
    }

    clearErrorMessages() {
        this._errorMessages = [];
    }

    hasErrors(): boolean {
        return this._errorMessages.length > 0;
    }

    getLoading(): boolean {
        return this._loading;
    }

    setLoading(value: boolean): void {
        this._loading = value;
    }

    public toggleLoading(): void {
        this._loading = !this._loading;
    }
}

const errorStore = new ErrorStore();
export default errorStore;
