import { makeAutoObservable } from "mobx";
import { Plan } from "./Plan";

export class OrderProject {
    private id: string;
    private shortId: string;
    private description: string;
    private orderComment: string | undefined;
    private objectPlans: Plan[];
    private name: string | undefined;

    constructor(shortId: string, id: string) {
        makeAutoObservable(this);
        this.objectPlans = [];
        this.shortId = shortId;
        this.description = "";
        this.id = id;
    }

    setObjectplans(plans: Plan[]): void {
        this.objectPlans = plans;
    }

    getObjectPlans() {
        return this.objectPlans;
    }

    getCheckedObjects(): Plan[] {
        return this.objectPlans.filter((plan) => plan.getChecked());
    }

    setId(id: string): void {
        this.id = id;
    }
    getId() {
        return this.id;
    }

    setShortId(shortId: string): void {
        this.shortId = shortId;
    }
    getShortId() {
        return this.shortId;
    }
    setOrderComment(orderComment: string): void {
        this.orderComment = orderComment;
    }
    getOrderComment() {
        return this.orderComment;
    }

    setDescription(description: string): void {
        this.description = description;
    }
    getDescription() {
        return this.description;
    }

    setName(name: string) {
        this.name = name;
    }
    getName(): string | undefined {
        return this.name;
    }

    saveOrderGroupedByRoofSection(): void {}
}
