import { ProductType } from "../../common/models/enums";

export function productType2Color(productType: ProductType): string {
    const defaultColor = "#F5D6BA";
    switch (productType) {
        case ProductType.ROOFACCESS:
            return "#fee9c6";
        case ProductType.RAILING:
            return "#e1f1f9";
        case ProductType.PSA:
        case ProductType.SLIDER:
            return "#e0efeb";
        case ProductType.STANDALONE:
        case ProductType.CORNER:
            return defaultColor + "1E";
        case ProductType.EDGE:
            return defaultColor + "5F";
        case ProductType.INTERMEDIATE:
            return defaultColor + "A0";
        case ProductType.ADDITION:
            return defaultColor + "DC";
        default:
            return defaultColor;
    }
}

export function class2Margin(className: string): number {
    switch (className) {
        case "PLAN":
            return 10;
        case "ROOFSECTION":
        case "PLANPRODUCTS":
        case "ADDITION":
            return 30;
        case "GUARDSYSTEM":
        case "PRODUCTGROUP":
        case "RAILSYSTEM":
        case "ROOFACCESS":
        case "ROOFSECTIONPRODUCTS":
        case "SYSTEM":
            return 50;
        case "PRODUCT":
            return 70;
        default:
            return 10;
    }
}
