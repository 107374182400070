import { observer } from "mobx-react";
import React from "react";
import { GuardSystem } from "../models/GuardSystem";
import { ProductGroup } from "../models/ProductGroup";
import { RailSystem } from "../models/RailSystem";
import { RoofAccess } from "../models/RoofAccess";
import { RoofSection } from "../models/RoofSection";
import { System } from "../models/System";
import projectStore from "../stores/ProjectStore";
import AddAdditionalProductModal from "./AddAdditionalProductModal";
import AddOnProductsListItem from "./AddOnProductsListItem";
import DrawOptionListItem from "./DrawOptionListItem";

function OptionSection() {
    return (
        <div style={{ overflow: "auto" }} className="optionComponent, componentContainer">
            {projectStore.getPsaProductenDialog() && <AddAdditionalProductModal />}
            <AddOnProductsListItem name={"GLEITER | PSA"} />
            {projectStore
                .getProject()
                .getObjectPlans()
                .map((plan, index) => {
                    return (
                        <div key={index}>
                            <DrawOptionListItem
                                key={plan.getId()}
                                plan={plan}
                                id={plan.getId()}
                                checked={plan.getChecked()}
                                name={plan.getTitle()}
                                item={plan}
                            />
                            {plan.getRoofSections().map((roofsection: RoofSection, index: number) => {
                                return (
                                    <div key={index}>
                                        <DrawOptionListItem
                                            key={plan.getId() + roofsection.getId()}
                                            plan={plan}
                                            id={roofsection.getId()}
                                            checked={roofsection.getChecked()}
                                            name={roofsection.getId() ? roofsection.getId() : "Dachfläche ohne ID"}
                                            item={roofsection}
                                        />
                                        {roofsection.getGuardSystems().map((gm: GuardSystem) => {
                                            return (
                                                <DrawOptionListItem
                                                    key={plan.getId() + roofsection.getId() + gm.getId()}
                                                    id={gm.getId()}
                                                    roofSection={roofsection}
                                                    plan={plan}
                                                    checked={gm.getChecked()}
                                                    name={gm.getName()}
                                                    item={gm}
                                                />
                                            );
                                        })}
                                        {roofsection.getRoofAccesses().map((rs: RoofAccess) => {
                                            return (
                                                <DrawOptionListItem
                                                    key={plan.getId() + roofsection.getId() + rs.getId()}
                                                    id={rs.getId()}
                                                    roofSection={roofsection}
                                                    plan={plan}
                                                    checked={rs.getChecked()}
                                                    name={rs.getId()}
                                                    item={rs}
                                                />
                                            );
                                        })}
                                        {roofsection.getRailSystems().map((rs: RailSystem) => {
                                            return (
                                                <DrawOptionListItem
                                                    key={plan.getId() + roofsection.getId() + rs.getId()}
                                                    id={rs.getId()}
                                                    roofSection={roofsection}
                                                    plan={plan}
                                                    checked={rs.getChecked()}
                                                    name={rs.getName()}
                                                    item={rs}
                                                />
                                            );
                                        })}
                                        {roofsection.getSystems().map((s: System) => {
                                            return (
                                                <DrawOptionListItem
                                                    key={plan.getId() + roofsection.getId() + s.getId()}
                                                    id={s.getId()}
                                                    roofSection={roofsection}
                                                    plan={plan}
                                                    checked={s.getChecked()}
                                                    name={s.getName()}
                                                    item={s}
                                                />
                                            );
                                        })}
                                        {roofsection.getProductGroups().map((pg: ProductGroup) => {
                                            if (pg.getProducts().length > 0) {
                                                return (
                                                    <DrawOptionListItem
                                                        key={plan.getId() + roofsection.getId() + pg.getProductType()}
                                                        plan={plan}
                                                        id={roofsection.getId()}
                                                        checked={pg.getChecked()}
                                                        name={pg.getProductType()}
                                                        item={pg}
                                                    />
                                                );
                                            }
                                        })}
                                    </div>
                                );
                            })}
                            {plan.getPlanProducts().getProducts().length > 0 && (
                                <DrawOptionListItem
                                    key={plan.getId() + plan.getId()}
                                    plan={plan}
                                    id={plan.getId()}
                                    checked={plan.getPlanProducts().getChecked()}
                                    name={"Freistehende Produkte"}
                                    item={plan.getPlanProducts()}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
}

export default observer(OptionSection);
