import { observer } from "mobx-react";
import React from "react";
import OptionListItem from "../../common/components/OptionListItem";
import exportStore from "../../common/stores/ExportStore";
import { LadderProject } from "../models/LadderProject";

function OptionSection() {
    const articleGroups =
        exportStore.getProject() === undefined ? [] : (exportStore.getProject() as LadderProject).getItemGroups();

    return (
        <div style={{ overflow: "auto" }} className="optionComponent, componentContainer">
            {articleGroups.map((articleGroup) => {
                return (
                    <OptionListItem
                        key={articleGroup.getName()}
                        name={articleGroup.getName()}
                        checked={articleGroup.getChecked()}
                        articleGroup={articleGroup}
                    />
                );
            })}
        </div>
    );
}

export default observer(OptionSection);
