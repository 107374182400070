import { observer } from "mobx-react";
import React from "react";
import SubOptionItem from "../../common/components/SubOptionItem";
import exportStore from "../../common/stores/ExportStore";
import { LadderProject } from "../models/LadderProject";

function LadderOrderOptionSection() {
    if (exportStore.getProject() === undefined) {
        return <div></div>;
    }

    return (
        <>
            <SubOptionItem
                checked={(exportStore.getProject() as LadderProject).getPartSum()}
                name={"Teilsumme(n) anzeigen"}
                onClickFunc={() => {
                    (exportStore.getProject() as LadderProject).togglePartSum();
                }}
            />
            <SubOptionItem
                checked={(exportStore.getProject() as LadderProject).getComments()}
                name={"Kommentar(e) anzeigen"}
                toolTipText={
                    "Der Kommentar kann bearbeitet werden, wenn Sie 'Kommentar' drücken. Wenn Sie den Kommentar wieder leeren, wird der Informationstext gespeichert"
                }
                onClickFunc={() => {
                    (exportStore.getProject() as LadderProject).toggleComments();
                }}
            />
        </>
    );
}

export default observer(LadderOrderOptionSection);
