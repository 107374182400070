import { Button, Modal, ModalContent, ModalFooter, ModalHeader } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React from "react";
import errorStore from "../../stores/ErrorStore";
import exportStore from "../../stores/ExportStore";

function ErrorModal() {
    return (
        <Modal onCloseClick={function noRefCheck() {}} size="sm" isOpen={errorStore.hasErrors()}>
            <ModalHeader title={"Error"} />
            <ModalContent>
                <>
                    {errorStore.getErrorMessages().map((message, index) => {
                        return <p key={index}>{message}</p>;
                    })}
                </>
            </ModalContent>
            <ModalFooter>
                <Button disabled={exportStore.getIsExporting()}>
                    <button onClick={() => errorStore.clearErrorMessages()}>OK</button>
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default observer(ErrorModal);
