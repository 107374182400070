import { makeAutoObservable } from "mobx";
import { IProject } from "../interfaces/IProject";
import { ExportItem } from "../models/export-item";
import selectlineAPI from "../services/SelectlineApi";
import { isUndefinedOrBlank } from "../utilities/StringUtil";
import errorStore from "./ErrorStore";

class ExportStore {
    private documentKey: string;
    private project?: IProject;
    private isExporting: boolean;
    private exportProgress: { totalCount: number; documentedCount: number; lockedCount: number };

    constructor() {
        makeAutoObservable(this);
        this.documentKey = "";
        this.isExporting = false;
        this.exportProgress = { totalCount: 0, documentedCount: 0, lockedCount: 0 };
    }

    async export() {
        this.setIsExporting(true);
        await this.saveItems().then(() => {
            this.setIsExporting(false);
        });
    }

    async saveItems(): Promise<void> {
        if (isUndefinedOrBlank(this.documentKey)) {
            errorStore.addErrorMessage("DocumentKey is not set!");
            return;
        }
        if (this.project === undefined) {
            errorStore.addErrorMessage("Project is not set!");
            return;
        }

        const exportItems = this.project.getExportItems();
        this.setExportProgress({ totalCount: exportItems.length, documentedCount: 0, lockedCount: 0 });

        if (!isUndefinedOrBlank(this.project.getId())) {
            try {
                await selectlineAPI.addProjectIdToDocument(this.documentKey, this.project.getId());
            } catch (error: any) {
                errorStore.addErrorMessage(error);
                return;
            }
        }
        for (const item of exportItems) {
            try {
                await item.saveItem(this.documentKey);
                this.incrementDocumentedCount();
            } catch (error: any) {
                errorStore.addErrorMessage(error);
                this.incrementLockedCount();
            }
        }
    }

    public showItems(): JSX.Element[] {
        if (this.project === undefined) {
            return [];
        }
        return this.project.getExportItems().map((item: ExportItem, index: number) => item.showItem(index));
    }

    public getProject(): IProject | undefined {
        return this.project;
    }

    public setProject(project: IProject) {
        this.project = project;
    }

    public resetProject() {
        this.project = undefined;
    }

    public getDocumentKey() {
        return this.documentKey;
    }

    public setDocumentKey(value: string) {
        this.documentKey = value;
    }

    public getIsExporting() {
        return this.isExporting;
    }

    public setIsExporting(value: boolean) {
        this.isExporting = value;
    }

    public getExportProgress() {
        return this.exportProgress;
    }

    public setExportProgress(exportProgress: { totalCount: number; documentedCount: number; lockedCount: number }) {
        this.exportProgress = exportProgress;
    }

    public incrementDocumentedCount() {
        this.exportProgress.documentedCount++;
    }

    public incrementLockedCount() {
        this.exportProgress.lockedCount++;
    }
}

const exportStore = new ExportStore();
export default exportStore;
