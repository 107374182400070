import { DropdownWithButton, IconDraw } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import PropTypes, { InferProps } from "prop-types";
import React from "react";
import DrawSection from "../../../draw/components/DrawSection";
import projectStore from "../../../draw/stores/ProjectStore";
import LadderImportSection from "../../../ladder-config/components/LadderImportSection";
import TenderFixSection from "../../../tenderfix/components/TenderFixSection";
import { ImporterType } from "../../models/enums";
import exportStore from "../../stores/ExportStore";

function resetAllProjectStores() {
    projectStore.resetProject();
    exportStore.resetProject();
}

function ImportSection({ importer, setImporter }: InferProps<typeof ImportSection.propTypes>) {
    const getProjectSpecificPart = (): JSX.Element => {
        switch (importer) {
            case ImporterType.LADDER_CONFIG:
                return <LadderImportSection />;
            case ImporterType.TENDERFIX:
                return <TenderFixSection />;
            default:
                return <DrawSection />;
        }
    };

    return (
        <div style={{ overflow: "auto" }} className="infoComponent, componentContainer">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className="box noBorder">
                    <DropdownWithButton
                        buttonText="Quelle wechseln"
                        items={[
                            {
                                icon: <IconDraw />,
                                onClick: function noRefCheck() {
                                    setImporter?.(ImporterType.DRAW);
                                    resetAllProjectStores();
                                },
                                text: "Draw",
                                checkmark: importer === ImporterType.DRAW,
                            },
                            {
                                onClick: function noRefCheck() {
                                    setImporter?.(ImporterType.LADDER_CONFIG);
                                    resetAllProjectStores();
                                },
                                text: "Steigtechnik Konfigurator",
                                checkmark: importer === ImporterType.LADDER_CONFIG,
                            },
                            {
                                onClick: function noRefCheck() {
                                    setImporter?.(ImporterType.TENDERFIX);
                                    resetAllProjectStores();
                                },
                                text: "TenderFix",
                                checkmark: importer === ImporterType.TENDERFIX,
                            },
                        ]}
                        onToggle={function noRefCheck() {}}
                        width={230}
                    />
                    {getProjectSpecificPart()}
                </div>
                <div id={"lowerleft"}>Version: 1.6.0</div>
            </div>
        </div>
    );
}

ImportSection.propTypes = {
    importer: PropTypes.string,
    setImporter: PropTypes.func,
};

export default observer(ImportSection);
