import { observer } from "mobx-react";
import React from "react";
import ResultDrawSection from "../../../draw/components/ResultDrawSection";
import LadderOrderOptionSection from "../../../ladder-config/components/LadderOrderOptionSection";
import { ImporterType } from "../../models/enums";
import ExportSection from "./ExportSection";

function ResultSection(props: { importer: string | undefined }) {
    switch (props.importer) {
        case ImporterType.LADDER_CONFIG:
            return (
                <ExportSection>
                    <LadderOrderOptionSection />
                </ExportSection>
            );
        case ImporterType.TENDERFIX:
            return <ExportSection />;
        default:
            return <ResultDrawSection />;
    }
}
export default observer(ResultSection);
