import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { ItableRowItem } from "../../common/interfaces/ITableRowItem";
import { ExportArticle } from "../../common/models/export-article";
import selectlineAPI from "../../common/services/SelectlineApi";
import projectStore from "../stores/ProjectStore";
import { productType2Color } from "../utilities/EvaluateHtmlProperty";
import { SortProductsBy } from "./enums";
import { Plan } from "./Plan";
import { RoofSection } from "./RoofSection";

export class Product extends ExportArticle implements ItableRowItem {
    private _partOf: string;
    private _plan: Plan | undefined;
    private _roofsection: RoofSection | null;
    private _inSystem: boolean;
    isEnforced: boolean | undefined;

    constructor(id: string, name: string, amount: number, plan?: Plan) {
        super(id, name, amount);
        this._partOf = "";
        if (plan != undefined) {
            this._plan = plan;
        }
        this._roofsection = null;
        this._inSystem = false;
        this.unit = "";
    }

    getInSystem(): boolean {
        return this._inSystem;
    }

    setInSystem(value: boolean) {
        this._inSystem = value;
    }

    getPartOf(): string {
        return this._partOf;
    }

    setPartOf(value: string): void {
        this._partOf = value;
    }

    getPlan(): Plan | undefined {
        return this._plan;
    }

    setPlan(value: Plan) {
        this._plan = value;
    }

    getRoofSection(): RoofSection | null {
        return this._roofsection;
    }

    setRoofSection(value: RoofSection | null) {
        this._roofsection = value;
    }

    saveProductInSelectline(documentKey: string): void {
        selectlineAPI.saveArticle(this, documentKey);
    }

    showItem(index: number): JSX.Element {
        return (
            <TableRow
                style={{
                    backgroundColor: productType2Color(this.getType()),
                }}
                key={index + this.getName() + "" + this.getPartOf()}
            >
                <TableCell style={{ fontSize: "0.8rem" }}>{this.getName()}</TableCell>
                <TableCell style={{ fontSize: "0.8rem" }} align="right">
                    {this.getType()}
                </TableCell>
                <TableCell style={{ fontSize: "0.8rem" }} align="right">
                    {this.getAmount() + " " + this.getUnit()}
                </TableCell>
                <TableCell style={{ fontSize: "0.8rem" }} align="right">
                    {projectStore.getSortOn() == SortProductsBy.PLAN
                        ? this.getPlan()?.getTitle()
                        : projectStore.getSortOn() === SortProductsBy.ROOFSECTION
                          ? this.getRoofSection()?.getId()
                          : this.getPartOf()}
                </TableCell>
            </TableRow>
        );
    }

    clone = (): Product => {
        const clonedProduct = new Product(this.getId(), this.getName(), this.getAmount(), this.getPlan());
        clonedProduct.setType(this.getType());
        clonedProduct.setAlternative(this.getAlternative());
        clonedProduct.setInSystem(this.getInSystem());
        clonedProduct.setPartOf(this.getPartOf());
        clonedProduct.setRoofSection(this.getRoofSection());
        clonedProduct.setUnit(this.getUnit());
        clonedProduct.isEnforced = this.isEnforced;
        return clonedProduct;
    };
}
