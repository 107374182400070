import { Button, IconDownload } from "@abs-safety/lock-book-web-ui";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AttributeComponent from "../../common/components/AttributeComponent";
import errorStore from "../../common/stores/ErrorStore";
import exportStore from "../../common/stores/ExportStore";
import { getProject } from "../services/TenderFixApi";

function TenderFixSection() {
    const [projectId, setProjectId] = useState("");
    const params = useParams();
    useEffect(() => {
        if (params.projectId) {
            setProjectId(params.projectId);
        }
        if (params.documentKey) {
            exportStore.setDocumentKey(params.documentKey);
        }
    }, [params.projectId, params.documentKey]);

    return (
        <div>
            <h3 className="headerTitleBox heading">TenderFix</h3>
            <AttributeComponent readonly={true} onChange={setProjectId} label={"TenderFix Id"} value={projectId} />
            <div className="headerTitleBox">
                <Button className="button">
                    <button
                        onClick={() => {
                            getProject(projectId);
                        }}
                        disabled={errorStore.getLoading() || exportStore.getIsExporting()}
                    >
                        Projekt laden
                        <IconDownload height={15} color="#FFFFFF" />
                    </button>
                </Button>
            </div>
            <AttributeComponent readonly={true} label={"Angebotsnummer"} value={exportStore.getDocumentKey()} />
        </div>
    );
}
export default observer(TenderFixSection);
